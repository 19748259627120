import { Paper, Stack, Button } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EventIcon from '@mui/icons-material/Event'
import SubjectIcon from '@mui/icons-material/Subject'
import SettingsIcon from '@mui/icons-material/Settings'
import { useNavigate } from 'react-router-dom'
import { FooterType2 } from './subcomponents/Footer'
import styles from '../styles/homePage.module.css'
import homePageColoredBoxOne from '../assets/HomePageColoredBoxOne.png'
import homePageColoredBoxTwo from '../assets/HomePageColoredBoxTwo.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { textTransform } from '@mui/system'

export const HomePage = (props) => {
  const navigate = useNavigate()
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '100px',
        }}
      >
        <Paper
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '333.75px',
            height: '100px',
            borderRadius: '16px',
            backgroundColor: 'rgba(17, 59, 2, 1)',
            color: 'rgba(193, 254, 175, 1)',
            margin: '10px 0px',
          }}
          elevation={3}
          onClick={() => navigate('/create')}
        >
          <span
            style={{
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '24px',
              marginLeft: '16px', // Added margin to push to the far left
            }}
          >
            Create Campaign
          </span>
          <AddCircleIcon
            style={{
              width: '48px',
              height: '48px',
              marginRight: '16px', // Added margin to push to the far right
            }}
          />
        </Paper>
        <Paper
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '333.75px',
            height: '100px',
            borderRadius: '16px',
            backgroundColor: 'rgba(17, 59, 2, 1)',
            color: 'rgba(193, 254, 175, 1)',
            margin: '10px 0px',
          }}
          elevation={3}
          onClick={() => navigate('/active')}
        >
          <span
            style={{
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '24px',
              marginLeft: '16px', // Added margin to push to the far left
            }}
          >
            Active Campaigns
          </span>
          <EventIcon
            style={{
              width: '48px',
              height: '48px',
              marginRight: '16px', // Added margin to push to the far right
            }}
          />
        </Paper>
        <Paper
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '333.75px',
            height: '100px',
            borderRadius: '16px',
            backgroundColor: 'rgba(17, 59, 2, 1)',
            color: 'rgba(193, 254, 175, 1)',
            margin: '10px 0px',
          }}
          elevation={3}
          onClick={() => navigate('/inactive')}
        >
          <span
            style={{
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '24px',
              marginLeft: '16px', // Added margin to push to the far left
            }}
          >
            Campaign History
          </span>
          <SubjectIcon
            style={{
              width: '48px',
              height: '48px',
              marginRight: '16px', // Added margin to push to the far right
            }}
          />
        </Paper>
        <Paper
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '333.75px',
            height: '100px',
            borderRadius: '16px',
            backgroundColor: 'rgba(17, 59, 2, 1)',
            color: 'rgba(193, 254, 175, 1)',
            margin: '10px 0px',
          }}
          elevation={3}
          onClick={() => navigate('/settings')}
        >
          <span
            style={{
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '24px',
              marginLeft: '16px', // Added margin to push to the far left
            }}
          >
            Settings
          </span>
          <SettingsIcon
            style={{
              width: '48px',
              height: '48px',
              marginRight: '16px', // Added margin to push to the far right
            }}
          />
        </Paper>
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 2 }}
          className={styles.ColoredBoxContainer}
        >
          <div className={styles.ColoredBoxBackgroundOne}>
            <img
              src={homePageColoredBoxOne}
              alt="homePageColoredBoxOne"
              className={styles.ColoredBoxImage}
            />
            <div className={styles.ColoredBoxHeaderOne}>Purchase Crypto</div>
            <Button
              className={styles.ColoredBoxButtonOne}
              variant="outlined"
              sx={{ textTransform: 'capitalize' }}
              onClick={() => navigate('/stripe')}
            >
              <Stack direction="row" spacing={1}>
                <div className={styles.ColoredBoxButtonTextOne}>
                  Stripe On-Ramp
                </div>
                <ArrowForwardIcon className={styles.ColoredBoxButtonIconOne} />
              </Stack>
            </Button>
          </div>
          {/* <div className={styles.ColoredBoxBackgroundTwo}>
            <img
              src={homePageColoredBoxTwo}
              alt="homePageColoredBoxTwo"
              className={styles.ColoredBoxImage}
            />
            <div className={styles.ColoredBoxHeaderTwo}>
              Customize your profile
            </div>
            <Button
              className={styles.ColoredBoxButtonTwo}
              variant="outlined"
              sx={{ textTransform: 'capitalize' }}
            >
              <Stack direction="row" spacing={1}>
                <div className={styles.ColoredBoxButtonTextTwo}>
                  Finish Setting Up
                </div>
                <ArrowForwardIcon className={styles.ColoredBoxButtonIconTwo} />
              </Stack>
            </Button>
          </div> */}
        </Stack>
      </div>
      <FooterType2 />
    </>
  )
}

import './App.css'
import ResponsiveAppBar from './components/subcomponents/AppBar'
import { useLocation, Route, Routes } from 'react-router-dom'
import AboutUsPage from './components/staticPages/AboutUs'
import HowItWorks from './components/staticPages/HowItWorks'
import FAQPage from './components/staticPages/FAQ'
import { CircularProgress, Container, Modal, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { createContext } from 'react'
import { SetupUser } from './components/SetupUser'
import { EventsTabTableWrapper } from './components/EventsTabTable'
import { NewLanderPage } from './components/NewLanderPage'
import { ContactUs } from './components/staticPages/ContactUs'
import { TermsOfUse } from './components/staticPages/TermsOfUse'
import { Web3ModalProvider } from './components/web3ModalProvider'
import { useWeb3Service } from './services/web3Service'
import { Close } from '@mui/icons-material'
import { getUserIP } from './utils/utils'
import ip3country from 'ip3country'
import { OFAC_SANCTIONED_COUNTRY_CODES } from './utils/utils'
import { set } from 'ip3country/src/ip_supalite'
import { ConnectWalletScreen } from './components/ConnectWalletScreen'
import StripeOnramp from './components/StripeOnramp'

ip3country.init()
// for showing console logs on mobile

const regex =
  /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
const isMobile = regex.test(navigator.userAgent)

// if (isMobile) {
if (false) {
  console.log('Mobile device detected')
  const log = console.log
  const err = console.error

  console.log = function () {
    const args = Array.from(arguments)

    const stringArgs = args.map((arg) => {
      if (typeof arg === 'object') {
        return JSON.stringify(arg, null, 2)
      }
      return arg
    })

    log.apply(console, stringArgs)
  }

  console.error = function () {
    const args = Array.from(arguments)

    const stringArgs = args.map((arg) => {
      if (typeof arg === 'object') {
        return JSON.stringify(arg, null, 2)
      }
      return arg
    })

    err.apply(console, stringArgs)
  }
}

// (function () {
//   const prependToBody = (s) => {
//     const outputEl = document.createElement("div");
//     if (typeof s === "object") {
//       s = JSON.stringify(s, null, 2);
//     }
//     outputEl.textContent = s;
//     document.body.prepend(outputEl);
//   };
//   console.log = (s, d, f) => {
//     prependToBody(s);
//     if (d) {
//       prependToBody(d);
//     }
//     if (f) {
//       prependToBody(f);
//     }
//   }
//   console.warn = (s,d,f) => {
//     prependToBody(s);
//     if (d) {
//       prependToBody(d);
//     }
//     if (f) {
//       prependToBody(f);
//     }
//   }
//   console.error = (s,d,f) => {
//     prependToBody(s);
//     if (d) {
//       prependToBody(d);
//     }
//     if (f) {
//       prependToBody(f);
//     }
//   }
// })();

var getStackTrace = function () {
  var obj = {}
  Error.captureStackTrace(obj, getStackTrace)
  return obj.stack
}

// (function () {
//   const log = console.log;
//   const err = console.error;
//   console.log = function () {
//     let pathString = getStackTrace() + '\n'
//     let formatArgs = [];
//     for (let i = 0; i < arguments.length; i++) {
//       if (typeof arguments[i] === 'object') {
//         formatArgs.push(JSON.stringify(arguments[i], null, 2));
//       } else {
//         formatArgs.push(arguments[i]);
//       }
//     }
//     log.apply(console, formatArgs);
//   }

//   console.error = function () {
//     let pathString = getStackTrace() + '\n'
//     let formatArgs = [];
//     for (let i = 0; i < arguments.length; i++) {
//       if (typeof arguments[i] === 'object') {
//         formatArgs.push(JSON.stringify(arguments[i], null, 2));
//       } else {
//         formatArgs.push(arguments[i]);
//       }
//     }
//     err.apply(console, formatArgs);
//   }
// })()

export const ProviderAdapterContext = createContext(null)

const InteriorApp = () => {
  const { address, currentUser, setCurrentUser, status, disconnect } =
    useWeb3Service()

  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [snackbarMsg, setSnackbarMsg] = React.useState('')
  const [geoBlocked, setGeoBlocked] = React.useState(false)

  const [loading, setLoading] = React.useState(true)

  let location = useLocation()

  const generateBackgroundColor = () => {
    const isSuccessfulEventPath =
      location.pathname.toLowerCase() === '/successfulevent'.toLowerCase()
    if (isSuccessfulEventPath) {
      return 'rgba(1, 20, 2, 1)'
    } else {
      return '#2E2E2E'
    }
  }

  useEffect(() => {
    getUserIP().then((ip) => {
      const cc = ip3country.lookupStr(ip)
      if (OFAC_SANCTIONED_COUNTRY_CODES.includes(cc)) {
        setGeoBlocked(true)
        setSnackbarMsg(
          'Your country (' +
            cc +
            ') is listed in the OFAC list of sanctioned countries, and as such we cannot allow you to access Tipping Point.'
        )
        setSnackbarOpen(true)
      } else {
        setGeoBlocked(false)
      }
    })

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const BaseApp = (props) => {
    const { status } = props

    useEffect(() => {
      console.log('Status updated:', status)
    }, [status])

    return (
      <>
        {loading ? (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : (
          <>
            {status === 'logged-in' ? (
              <EventsTabTableWrapper
                currentEthAddress={address}
                userInfo={currentUser}
                setCurrentUser={setCurrentUser}
              />
            ) : status === 'new-user' ? (
              <SetupUser ethAddress={address} setCurrentUser={setCurrentUser} />
            ) : (
              <NewLanderPage geoBlocked={geoBlocked} />
            )}
          </>
        )}

        <Modal
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '300px',
            height: '200px',
            width: '60%',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '15px',
              backgroundColor: 'black',
              border: '2px solid #f1f1f1',
            }}
          >
            {!geoBlocked && (
              <Close
                style={{
                  color: '#a2a3a9',
                  cursor: 'pointer',
                }}
                onClick={() => setSnackbarOpen(false)}
              />
            )}
            <div
              style={{
                color: '#f1f1f1',
                textAlign: 'center',
                fontSize: '24px',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              {snackbarMsg}
            </div>
          </div>
        </Modal>
      </>
    )
  }

  return (
    <div
      className="App"
      style={{
        backgroundColor: generateBackgroundColor(),
      }}
    >
      <ResponsiveAppBar
        address={address}
        currentUser={currentUser}
        disconnectWallet={disconnect}
        status={status}
      />
      <Container maxWidth={'xl'} style={{ paddingTop: '30px', height: '100%' }}>
        <Routes location={location}>
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/howitworks" element={<HowItWorks />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/connectwallet" element={<ConnectWalletScreen />} />
          <Route path="/stripe" element={<StripeOnramp address={address} />} />
          <Route path="*" element={<BaseApp status={status} />} />
        </Routes>
      </Container>
    </div>
  )
}

const App = () => {
  return (
    <Web3ModalProvider>
      <InteriorApp />
    </Web3ModalProvider>
  )
}

export default App

import {
  Grid,
  Stack,
  Button,
  Divider,
  FormControl,
  OutlinedInput,
  Snackbar,
  Alert,
} from '@mui/material'
import { ConnectWalletButton } from './ConnectWalletButton'
import { VectorQuestionDropdown } from './staticPages/FAQ'
import { FooterType1 } from './subcomponents/Footer'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import heroLanderBackground from '../assets/HEROLanderBackground.png'
import heroLanderForeground from '../assets/HEROLanderForeground.png'
import heroLanderBackgroundTablet from '../assets/HEROLanderBackgroundTablet.png'
import heroLanderForegroundTablet from '../assets/HEROLanderForegroundTablet.png'
import heroLanderBackgroundDesktop from '../assets/HEROLanderBackgroundDesktop.png'
import heroLanderForegroundDesktop from '../assets/HEROLanderForegroundDesktop.png'
import { LanderVideoComponent } from './subcomponents/LanderVideoComponent'
import landerSignUpShowcaseMobile from '../assets/LanderSignUpShowcaseMobile.png'
import landerSignUpShowcaseDesktop from '../assets/LanderSignUpShowcaseDesktop.png'
import landerSignUpShowcaseTablet from '../assets/LanderSignUpShowcaseTablet.png'
import landerEventShowcaseMobile from '../assets/LanderEventShowcaseMobile.png'
import landerEventShowcaseTablet from '../assets/LanderEventShowcaseTablet.png'
import landerEventShowcaseDesktop from '../assets/LanderEventShowcaseDesktop.png'
import EventIcon from '@mui/icons-material/Event'
import SportsScoreIcon from '@mui/icons-material/SportsScore'
import ShareIcon from '@mui/icons-material/Share'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import LanderNewsletterMail from '../assets/LanderNewsletterMail.png'
import builtOnBaseIcon from '../assets/BuiltOnBaseIcon.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import styles from '../styles/NewLanderPage.module.css'
import { CoinbaseWalletLogo } from './subcomponents/CoinbaseWalletLogo'
import { axiosInstance } from '../services/webClient'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useWeb3Connect } from '../services/web3Service'

const LanderMainHeroComponent = (props) => {
  const { connectors, connect } = useWeb3Connect()
  const { onPressLogout, loading, ethAddress, geoBlocked } = props

  const handleCreateButtonPress = () => {
    const coinbaseSmartWallet = connectors.find(
      (connector) => connector.id === 'coinbaseWalletSDK'
    )
    if (coinbaseSmartWallet) {
      connect({ connector: coinbaseSmartWallet })
    } else {
      console.log('Coinbase Smart Wallet Connector not found')
    }
  }

  const [sessionStatus, setSessionStatus] = useState('not yet checked')
  const { open } = useWeb3Modal()

  return (
    <>
      <Stack direction={{ xs: 'column' }} spacing={{ xs: 2 }}>
        <Stack
          direction={'column'}
          spacing={{ xs: 3, md: 2, lg: 4 }}
          className={styles.LanderMainHeroComponent}
          justifyContent={'center'}
          alignItems={{ xs: 'center' }}
        >
          <div className={styles.LanderMainHeroComponentHeader}>
            Unlock the power of your crowd.
          </div>
          <Stack
            direction={{ xs: 'column', md: 'column-reverse' }}
            className={styles.LanderMainHeroComponentSubtextContainer}
            spacing={{ xs: 2, md: 4 }}
          >
            <div className={styles.LanderMainHeroComponentHeroImage}>
              <LanderVideoComponent />
            </div>
            <Stack
              direction={'column'}
              className={styles.LanderMainHeroComponentButtonContainer}
              spacing={{ xs: 2 }}
            >
              <div className={styles.LanderMainHeroComponentSubtext}>
                Tipping Point takes the stress out of funding by helping you
                plan and organize your projects and events.{' '}
              </div>
              <div className={styles.LanderMainHeroComponentSubtext}>
                Just set your goals, invite your network, and recieve your money
                instantly if your fundraiser succeeds.
              </div>
              <Stack
                direction={'row'}
                spacing={{ xs: 2 }}
                // className={styles.LanderMainHeroComponentButtonContainer}
              >
                {/* <div className={styles.LanderMainHeroComponentButton}>
                  <ConnectWalletButton
                    onPressLogout={onPressLogout}
                    loading={loading}
                    address={ethAddress}
                    geoBlocked={geoBlocked}
                  />
                </div>
                

                <Button
                  variant="contained"
                  className={styles.LanderMainHeroComponentCreateButton}
                  onClick={() => {
                    if (!geoBlocked) handleCreateButtonPress()
                  }}
                >
                  <Stack
                    direction={'row'}
                    spacing={{ xs: 1, md: 3 }}
                    alignItems={'center'}
                  >
                    <CoinbaseWalletLogo />
                    <div>Create Wallet</div>
                  </Stack>
                </Button> */}
                <w3m-connect-button />
              </Stack>
              <img
                src={builtOnBaseIcon}
                alt="builtOnBaseIcon"
                className={styles.LanderMainHeroComponentBuiltOnBaseIcon}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

const LanderEventShowcaseComponent = () => {
  const [width, setWidth] = useState(window.innerWidth)
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const navigate = useNavigate()

  return (
    <>
      <Stack
        direction={{ xs: 'column-reverse', md: 'column' }}
        spacing={{ xs: 6, md: 6, lg: 8 }}
        alignItems={'center'}
      >
        <Stack
          direction={'column'}
          spacing={{ xs: 6, md: 6, lg: 8 }}
          alignItems={{ xs: 'center' }}
        >
          {/* <div className={styles.LanderEventShowcaseComponentHeader}>Have an event? Set your goals, 
                    raise money from your network. <span className={styles.LanderEventShowcaseComponentHeaderAltColor}>Take the stress out of funding.</span></div> */}
          <div className={styles.LanderFullWidthText}>
            Sign-up is instant. Just connect your wallet, create your profile,
            and
            <span className={styles.LanderFullWidthTextAltColor}>
              {' '}
              start bringing your ideas to life.
            </span>
          </div>
          <img
            src={
              width > 1199
                ? landerSignUpShowcaseDesktop
                : width > 899
                ? landerSignUpShowcaseTablet
                : landerSignUpShowcaseMobile
            }
            alt="landerEventShowcase"
            className={styles.LanderEventShowcaseComponentHeroImage}
          />
          <div className={styles.LanderFullWidthText}>
            Try as many fundraisers as you like for free - only pay for the ones
            that succeed.
          </div>
        </Stack>
        <Divider className={styles.LanderDivider} />
        <Button
          variant={'contained'}
          className={styles.LanderEventShowcaseComponentButton}
          onClick={() => {
            navigate('/howItWorks')
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        >
          How It Works
        </Button>
      </Stack>
    </>
  )
}

const LanderRiskRewardComponent = (props) => {
  const { onPressLogout, loading, ethAddress } = props

  return (
    <>
      <Stack
        direction="column"
        className={styles.LanderRiskRewardComponentContainer}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={{ xs: 2, lg: 4 }}
      >
        <div className={styles.LanderRiskRewardComponentSubtext}>
          Get started now:
        </div>
        {/* <img src={riskRewardImage} alt="riskRewardImage" className={styles.LanderRiskRewardComponentImage}/> */}
        <div className={styles.LanderRiskRewardComponentButton}>
          <ConnectWalletButton
            onPressLogout={onPressLogout}
            loading={loading}
            address={ethAddress}
          />
        </div>
      </Stack>
    </>
  )
}

const CompetitorFeesComponent = () => {
  return (
    <>
      <Stack
        direction={'column'}
        spacing={{ xs: 2, md: 3 }}
        alignItems={'center'}
        className={styles.CompetitorFeesComponentContainer}
      >
        <Stack
          direction={'row'}
          style={{ width: '100%', position: 'relative' }}
          justifyContent={'space-between'}
        >
          <div className={styles.CompetitorFeesComponentHeader}>Company</div>
          <div className={styles.CompetitorFeesMiddleLeftHeader}>
            Platform Fee
          </div>
          <div className={styles.CompetitorFeesMiddleRightHeader}>
            Processing Fee
          </div>
          <div className={styles.CompetitorFeesComponentHeader}>Total</div>
        </Stack>
        <Divider className={styles.CompetitorFeesComponentDivider} />
        <Stack
          direction={'row'}
          style={{ width: '100%', position: 'relative' }}
          justifyContent={'space-between'}
        >
          <div
            className={styles.CompetitorFeesComponentSubtextLeft}
            style={{ textAlign: 'left !important' }}
          >
            Kickstarter
          </div>
          <div className={styles.CompetitorFeesMiddleLeftSubtext}>5%</div>
          <div className={styles.CompetitorFeesMiddleRightSubtext}>3%</div>
          <div className={styles.CompetitorFeesComponentSubtextRight}>8%</div>
        </Stack>
        <Divider className={styles.CompetitorFeesComponentDivider} />
        <Stack
          direction={'row'}
          style={{ width: '100%', position: 'relative' }}
          justifyContent={'space-between'}
        >
          <div
            className={styles.CompetitorFeesComponentSubtextLeft}
            style={{ textAlign: 'left !important' }}
          >
            GoFundMe (Personal)
          </div>
          <div className={styles.CompetitorFeesMiddleLeftSubtext}>0%</div>
          <div className={styles.CompetitorFeesMiddleRightSubtext}>2.90%</div>
          <div className={styles.CompetitorFeesComponentSubtextRight}>
            2.90%
          </div>
        </Stack>
        <Divider className={styles.CompetitorFeesComponentDivider} />
        <Stack
          direction={'row'}
          style={{ width: '100%', position: 'relative' }}
          justifyContent={'space-between'}
        >
          <div
            className={styles.CompetitorFeesComponentSubtextLeft}
            style={{ textAlign: 'left !important' }}
          >
            GoFundMe (Charity)
          </div>
          <div className={styles.CompetitorFeesMiddleLeftSubtext}>0%</div>
          <div className={styles.CompetitorFeesMiddleRightSubtext}>2.20%</div>
          <div className={styles.CompetitorFeesComponentSubtextRight}>
            2.20%
          </div>
        </Stack>
        <Divider className={styles.CompetitorFeesComponentDivider} />
        <Stack
          direction={'row'}
          style={{ width: '100%', position: 'relative' }}
          justifyContent={'space-between'}
        >
          <div
            className={styles.CompetitorFeesComponentSubtextLeft}
            style={{ textAlign: 'left !important' }}
          >
            Indiegogo
          </div>
          <div className={styles.CompetitorFeesMiddleLeftSubtext}>5%</div>
          <div className={styles.CompetitorFeesMiddleRightSubtext}>3%</div>
          <div className={styles.CompetitorFeesComponentSubtextRight}>8%</div>
        </Stack>
        <Divider className={styles.CompetitorFeesComponentDivider} />
        <Stack
          direction={'row'}
          style={{ width: '100%', position: 'relative' }}
          justifyContent={'space-between'}
        >
          <div
            style={{ color: '#f1f1f1', fontWeight: '600' }}
            className={styles.CompetitorFeesComponentSubtextLeft}
          >
            Tipping Point
          </div>
          <div
            style={{ color: '#f1f1f1', fontWeight: '600' }}
            className={styles.CompetitorFeesMiddleLeftSubtext}
          >
            1.5%
          </div>
          <div
            style={{ color: '#f1f1f1', fontWeight: '600' }}
            className={styles.CompetitorFeesMiddleRightSubtext}
          >
            0%
          </div>
          <div
            style={{ color: '#c1feaf', fontWeight: '600' }}
            className={styles.CompetitorFeesComponentSubtextRight}
          >
            1.5%
          </div>
        </Stack>
        {/* <img src={competitorFeesBackground} alt="competitorFeesBackground" className={styles.CompetitorFeesBackground}/> */}
      </Stack>
    </>
  )
}

const NewLanderPage = (props) => {
  const { onPressLogout, loading, ethAddress, geoBlocked } = props

  const [width, setWidth] = useState(window.innerWidth)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [successfulEmailSubmission, setSuccessfulEmailSubmission] =
    useState(false)

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleEmailSubmission = async () => {
    if (emailValue === null || emailValue === '') {
      setEmailValid(false)
      setOpenSnackBar(true)
      return
    }
    setEmailValid(true)
    await axiosInstance
      .post('/mailingList', {
        email: emailValue,
      })
      .then((response) => {
        setSuccessfulEmailSubmission(true)
        setOpenSnackBar(true)
        console.log('Email Submitted', response)
        setEmailValue('')
      })
      .catch((error) => {
        setSuccessfulEmailSubmission(false)
        setOpenSnackBar(true)
        console.log('Email Submission Error', error)
      })
  }

  const navigate = useNavigate()

  return (
    <>
      <Grid
        container
        spacing={{ xs: 3, lg: 8 /*md: 8, lg: 12*/ }}
        justifyContent={'space-evenly'}
        sx={{ overflowY: 'hidden', overflowX: 'hidden' }}
      >
        <Grid item xs={12}>
          <LanderMainHeroComponent
            onPressLogout={onPressLogout}
            loading={loading}
            ethAddress={ethAddress}
            geoBlocked={geoBlocked}
          />
        </Grid>
        <Grid item xs={12}>
          <LanderEventShowcaseComponent />
        </Grid>
        <Grid item xs={12} sx={{ mt: '50px' }}>
          <Grid container spacing={{ xs: 1 }}>
            <Grid item xs={3}>
              <Stack direction={'column'} spacing={{ xs: 4 }}>
                <EventIcon className={styles.LanderFunctionalityImage} />
                <div className={styles.LanderFunctionalityHeader}>
                  Create A Campaign
                </div>
                {width > 899 && (
                  <div className={styles.LanderFunctionalitySubtext}>
                    Outline what your Campaign is all about.
                  </div>
                )}
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction={'column'} spacing={{ xs: 4 }}>
                <SportsScoreIcon className={styles.LanderFunctionalityImage} />
                <div className={styles.LanderFunctionalityHeader}>
                  Set Your Goals
                </div>
                {width > 899 && (
                  <div className={styles.LanderFunctionalitySubtext}>
                    Define the financial or attendance target.
                  </div>
                )}
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction={'column'} spacing={{ xs: 4 }}>
                <ShareIcon className={styles.LanderFunctionalityImage} />
                <div className={styles.LanderFunctionalityHeader}>
                  Invite Your Network
                </div>
                {width > 899 && (
                  <div className={styles.LanderFunctionalitySubtext}>
                    Rally your network to join and contribute.
                  </div>
                )}
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction={'column'} spacing={{ xs: 4 }}>
                <TrackChangesIcon className={styles.LanderFunctionalityImage} />
                <div className={styles.LanderFunctionalityHeader}>
                  Track Your Progress
                </div>
                {width > 899 && (
                  <div className={styles.LanderFunctionalitySubtext}>
                    Watch as your Campaign tips closer to success with each
                    contribution.
                  </div>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Divider className={styles.LanderDivider} />
        </Grid>
        <Grid item xs={12} md={12}>
          <img
            src={
              width > 1199
                ? landerEventShowcaseDesktop
                : width > 899
                ? landerEventShowcaseTablet
                : landerEventShowcaseMobile
            }
            alt="landerEventShowcase"
            className={styles.LanderEventShowcaseComponentHeroImage}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider className={styles.LanderDivider} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack
            direction={'column'}
            className={styles.LanderHeaderWithTextContainer}
            spacing={{ xs: 2 }}
            alignItems={{ xs: 'center' }}
          >
            <div className={styles.LanderHeaderWithTextHeader}>
              Savings Unlocked
            </div>
            <div className={styles.LanderHeaderWithTextSubtext}>
              Ditch the banks. Keep more of your money.
            </div>
            <CompetitorFeesComponent />
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider className={styles.LanderDivider} />
        </Grid>
        {/* {process.env.REACT_APP_ENVIRONMENT === 'development' &&
                <>
                    <Grid item xs={12} lg={12}>
                        <Divider className={styles.LanderDivider}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}
                        sx={{
                            pt: '20px !important',
                        }}
                    >
                        <LanderRiskRewardComponent
                            onPressLogout={onPressLogout}
                            loading={loading}
                            ethAddress={ethAddress}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Divider className={styles.LanderDivider}/>
                    </Grid>
                </>
                }    */}
        <Grid item xs={12} md={12} lg={12}>
          <Stack direction={'column'} spacing={{ xs: 3, md: 6 }}>
            <Stack
              direction={'column'}
              spacing={{ xs: 2 }}
              alignItems={{ xs: 'center' }}
            >
              <div className={styles.LanderFAQHeader}>
                Get to know Tipping Point
              </div>
              <div className={styles.LanderFAQSubtext}>
                Here are our most frequently asked questions:
              </div>
            </Stack>
            <Stack
              direction={'column'}
              spacing={{ xs: 1 }}
              /*alignSelf={{md: 'center'}}*/ className={
                styles.LanderFAQDropdownContainer
              }
            >
              {/* <VectorQuestionDropdown
                heading={'Is Tipping Point only for actual in-person events?'}
                body={[
                  'No, Tipping Point is a general crowdfunding platform that allows users to raise money for any possible reason.',
                  'We simply use the word "Event" to refer to Tipping Point funding requests, rather than constantly repeating a phrase like "projects, events, campaigns, businesses or fundraisers."',
                  'Regardless of your goal, if you need to raise money Tipping Point can help you do it!',
                ]}
              /> */}
              <VectorQuestionDropdown
                heading={
                  'How is Tipping Point different from other crowdfunding platforms?'
                }
                body={[
                  'Tipping Point stands apart from other crowdfunding platforms in several important aspects:',
                  'Tipping Point helps you plan and organize your Campaigns prior to collecting any money, so your invitees only contribute once the project is guaranteed to have the support it needs to occur.',
                  'Once your Campaign has tipped, you receive your funds instantly - no more waiting days for payments to clear or the bank to open.',
                  'Tipping Point has the lowest fees of any crowdfunding platform: a flat 1.5%. And users only pay fees when the Campaign they’re contributing to tips.',
                  'Because it’s built on web3, Tipping Point has no access to user funds, cannot censor user transactions on the network, and cannot deplatform users from accessing the Tipping Point platform.',
                ]}
              />
              <VectorQuestionDropdown
                heading={'Is there a Tipping Point app I can download?'}
                body={[
                  'Tipping Point is not available as a standalone app from the Apple/Google store, but you can save the tippingpoint.app website as an icon to your phone or tablet home screen by clicking the menu in the top-right corner of your browser and selecting "Add to Home Screen."',
                ]}
              />
              <VectorQuestionDropdown
                heading={
                  'What personal information does Tipping Point collect from its users?'
                }
                body={[
                  "Tipping Point doesn't require any personal or financial information in order to create an account or use the platform.",
                  "Once you've connected your wallet, simply set a Display Name and you're ready to start using Tipping Point!",
                  '(Providing a phone number and email address are optional, and will only be used to send users notifications or updates regarding Tipping Point.)',
                ]}
              />

              {/* <VectorQuestionDropdown heading={} body={}/>
                            <VectorQuestionDropdown heading={} body={}/>
                            <VectorQuestionDropdown heading={} body={}/>
                            <VectorQuestionDropdown heading={} body={}/> */}
            </Stack>
            <Stack
              direction={'row'}
              spacing={1}
              className={styles.LanderFAQButtonContainer}
              onClick={() => {
                navigate('/FAQ')
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            >
              <div className={styles.LanderFAQButtonText}>
                View Complete FAQ
              </div>
              <ArrowForwardIcon
                style={{ color: 'white' }}
                className={styles.LanderFAQButtonIcon}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Divider className={styles.LanderDivider} />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Stack direction={'column'} spacing={{ xs: 2, md: 2, lg: 2 }}>
            <div className={styles.LanderNewsletterHeader}>
              Sign up for news and updates:
            </div>
            <FormControl variant="outlined">
              <OutlinedInput
                className={styles.LanderNewsletterInput}
                placeholder={
                  'Enter your email' +
                  `${width > 899 ? ' to stay up to date' : ''}`
                }
                inputProps={{ className: styles.LanderNewsletterInputText }}
                value={emailValue}
                onChange={(evt) => setEmailValue(evt.target.value)}
                startAdornment={
                  <img
                    src={LanderNewsletterMail}
                    alt="LanderNewsletterMail"
                    className={styles.LanderNewsletterMailIcon}
                  />
                }
                endAdornment={
                  <Button
                    variant="outlined"
                    className={styles.LanderNewsletterButton}
                    onClick={() => handleEmailSubmission()}
                  >
                    <div className={styles.LanderNewsletterButtonText}>
                      Send
                    </div>
                  </Button>
                }
                sx={{
                  '& .MuiOutlinedInput-input': {
                    fontSize: '20px !important',
                  },
                }}
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid item lg={12}>
          <FooterType1 />
        </Grid>
        <Grid item xs={12}>
          <div
            className={styles.LanderReturnToTopButton}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            Return to top
          </div>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={
            successfulEmailSubmission && emailValid ? 'success' : 'error'
          }
          onClose={() => setOpenSnackBar(false)}
          variant="filled"
        >
          {!emailValid
            ? 'Please enter a valid email address'
            : successfulEmailSubmission
            ? 'Email submitted successfully!'
            : 'Error submitting email. Please try again.'}
        </Alert>
      </Snackbar>
    </>
  )
}

export { NewLanderPage }

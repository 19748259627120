import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { useLocation, useNavigate } from 'react-router-dom'
import '@knocklabs/react/dist/index.css'
import { KnockNotificationIcon } from '../../services/notificationService'
import logoIcon from '../../assets/Logomark.png'
import avatar from '../../assets/Avatar.png'
import {
  KnockFeedProvider,
  KnockProvider,
  useKnockClient,
  useNotifications,
  useNotificationStore,
} from '@knocklabs/react'
import { Badge, Divider } from '@mui/material'
import { width } from '@mui/system'
import { axiosInstance } from '../../services/webClient'

const pages = [
  { pageText: 'ABOUT US', pageUrl: '/aboutUs' },
  { pageText: 'HOW IT WORKS', pageUrl: '/howItWorks' },
  // { pageText: 'GET STARTED', pageUrl: '/getStarted' },
  { pageText: 'FAQ', pageUrl: '/faq' },
  { pageText: 'CONTACT US', pageUrl: '/contactUs' },
]

function ResponsiveAppBar(props) {
  const { address, disconnectWallet, currentUser, status } = props
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [width, setWidth] = React.useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = (pageUrl) => {
    if (typeof pageUrl === 'string') {
      navigate(pageUrl)
    }
    setAnchorElNav(null)
  }

  const navigate = useNavigate()
  let location = useLocation()

  const generateBackground = () => {
    const isSuccessfulEventPath =
      location.pathname.toLowerCase() === '/successfulevent'.toLowerCase()
    if (isSuccessfulEventPath) {
      return 'rgba(1, 20, 2, 1)'
    } else {
      return '#2E2E2E'
    }
  }

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: generateBackground(),
        border: '1px solid rgba(67, 67, 67, 1)',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{ color: '#C1FEAF' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({ pageText, pageUrl }) => (
                <MenuItem
                  key={pageText}
                  onClick={() => handleCloseNavMenu(pageUrl)}
                >
                  <Typography textAlign="center">{pageText}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {pages.map(({ pageText, pageUrl }) => (
              <Button
                key={pageText}
                onClick={() => handleCloseNavMenu(pageUrl)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {pageText}
              </Button>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position:
                width > 1199 || (width <= 899 && !address)
                  ? 'absolute'
                  : 'static',
              left: width > 1199 || (width <= 899 && !address) ? '50%' : 'auto',
              transform:
                width > 1199 || (width <= 899 && !address)
                  ? 'translateX(-50%)'
                  : 'none',
            }}
          >
            <MenuItem>
              {<img src={logoIcon} alt="logo" onClick={() => {
                if(status === 'new-user') {
                  // Take user back to homepage by disconnecting wallet
                  disconnectWallet()
                }else{
                  navigate('/')
                }
              }} />}
            </MenuItem>
          </Box>

          {!!address && currentUser?.ethAddress && (
            <>
              <KnockProvider
                apiKey={process.env.REACT_APP_KNOCK_CLIENT_PUB_KEY}
                userId={address}
              >
                <KnockFeedProvider
                  feedId={'63e0b155-1421-4ce7-a78f-d7858f86ecd8'}
                  colorMode="dark"
                >
                  <NavBarMenuWithKnock
                    disconnectWallet={disconnectWallet}
                    address={address}
                    currentUser={currentUser}
                  />
                </KnockFeedProvider>
              </KnockProvider>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

const NavBarMenuWithKnock = (props) => {
  const { disconnectWallet, address, currentUser } = props
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const [notiCount, setNotiCount] = React.useState(0)
  const [profilePicSrc, setProfilePicSrc] = React.useState(currentUser.profilePic ? currentUser.profilePicSrc : avatar)


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const knockClient = useKnockClient()
  const feedClient = useNotifications(
    knockClient,
    '63e0b155-1421-4ce7-a78f-d7858f86ecd8'
  )
  const { items, metadata } = useNotificationStore(feedClient)
  React.useEffect(() => {
    feedClient.fetch()
  }, [feedClient])

  React.useEffect(() => {
    setNotiCount(metadata?.unread_count)
  }, [metadata])

  return (
    <Box sx={{}}>
      <>
        <Badge badgeContent={notiCount} color="secondary">
          <img
            src={profilePicSrc}
            alt="avatar"
            onClick={handleOpenUserMenu}
            id="avatar-img-element"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              cursor: 'pointer',
            }}
            onError={(e) => {
              console.log('error', e)
              setProfilePicSrc(avatar)
              if (currentUser?.profilePic) {
                setTimeout(() => {
                  setProfilePicSrc(currentUser.profilePicSrc)
                }, 5000)
              }
            }}
          />
        </Badge>

        <Menu
          sx={{
            mt: '45px',
            '& .MuiPaper-root': {
              backgroundColor: '#2E2E2E !important',
              color: 'white',
              borderRadius: '5px',
            },
          }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <div
            style={{
              color: '#c1feaf',
              fontSize: '14px',
              marginLeft: '5px',
              marginBottom: '5px',
            }}
          >
            {currentUser?.displayName}
          </div>
          <Divider
            sx={{
              backgroundColor: '#c1feaf',
              width: '100%',
              marginBottom: '8px',
            }}
          />
          <KnockNotificationIcon notiCount={notiCount} />

          <MenuItem
            onClick={() => {
              disconnectWallet()
              handleCloseUserMenu()
            }}
          >
            {address && (
              <Typography id="disconnect-wallet-menu-item" textAlign="center">
                Disconnect Wallet
              </Typography>
            )}
          </MenuItem>
        </Menu>
      </>
    </Box>
  )
}
export default ResponsiveAppBar

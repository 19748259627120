import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import { BottomNavBar } from './BottomNavBar'
import { Alert, Button, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../services/webClient'
import styles from '../styles/Settings.module.css'
import { color } from '@mui/system'
import { BACKEND_URL } from '../utils/utils'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Close } from '@mui/icons-material'

const Settings = (props) => {
  const { userInfo, setCurrentUser } = props

  const UserInfo = (props) => {
    const { userInfo, setCurrentUser } = props
    const [editingActive, setEditingActive] = useState(false)
    const [displayName, setDisplayName] = useState(userInfo?.displayName)
    const [displayNameError, setDisplayNameError] = useState(null)
    const [email, setEmail] = useState(userInfo?.email)
    const [phoneNumber, setPhoneNumber] = useState(userInfo?.phoneNumber)
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [submitEditError, setSubmitEditError] = useState(null)
    const [profileChanged, setProfileChanged] = useState(false)
    const [profilePicFile, setProfilePicFile] = React.useState(null)

    useEffect(() => {
      if (
        userInfo?.displayName !== displayName ||
        userInfo?.email !== email ||
        userInfo?.phoneNumber !== phoneNumber
      ) {
        setProfileChanged(true)
      } else {
        setProfileChanged(false)
      }
    }, [displayName, email, phoneNumber])

    const uploadimgFileToS3 = async (presignedUrl, imgFile) => {
      fetch(presignedUrl, {
        method: 'PUT',
        mode: 'cors',
        body: imgFile,
      })
        .then(async (response) => {
          const res = await response.text()
          console.log('uploadimgFileToS3 response', res)
        })
        .catch((error) => {
          console.error('uploadimgFileToS3 error', error)
        })
    }
    const handleEditProfileSubmit = async () => {
      if (!displayName) {
        setDisplayNameError('Display name is required')
        setSnackbarOpen(true)
        return
      }
      if (displayNameError) {
        setSnackbarOpen(true)
        return
      }
      await fetch(BACKEND_URL + '/user/updateUser', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ethAddress: userInfo.ethAddress,
          displayName: displayName,
          email: email,
          phoneNumber: phoneNumber,
          profilePic: profilePicFile ? true : false,
        }),
      })
        .then(async function (response) {
          const updatedUser = await response.json()
          if (updatedUser.presignedUrl) {
            uploadimgFileToS3(updatedUser.presignedUrl, profilePicFile)
          }
          setSubmitEditError(null)
          setSnackbarOpen(true)
          // give snackbar time to pop up before rerender
          setTimeout(() => {
            setCurrentUser({ ...updatedUser })
          }, 6000)
          setEditingActive(false)
        })
        .catch(function (error) {
          setSubmitEditError('Error updating profile')
          setSnackbarOpen(true)
          console.log('handleEditProfileSubmit', error)
        })
    }

    return (
      <>
        <Grid item xs={6}>
          <Stack spacing={2} direction="column">
            <Stack spacing={1} direction="column">
              <div className={styles.TextFieldLabel}>Ethereum Address</div>
              <TextField
                disabled={true}
                id="outlined-basic"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    color: '#a2a3a9',
                  },
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9 !important',
                    // border: '1px solid #a2a3a9 !important',
                  },
                  'input::placeholder': {
                    color: '#cac4d0 !important',
                    opacity: 1,
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#a2a3a9',
                    },
                    '&.Mui-error fieldset': {
                      borderColor: '#d32f2f',
                    },
                  },
                }}
                value={userInfo?.ethAddress}
              />
            </Stack>
            <Stack spacing={1} direction="column">
              <div className={styles.TextFieldLabel}>Display Name</div>
              <TextField
                disabled={!editingActive}
                error={displayNameError ? true : false}
                helperText={displayNameError}
                autoComplete="off"
                id="outlined-basic"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    color: '#a2a3a9',
                  },
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9 !important',
                    // border: '1px solid #a2a3a9 !important',
                  },
                  'input::placeholder': {
                    color: '#cac4d0 !important',
                    opacity: 1,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '2px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '2px',
                    },
                    '&:hover.Mui-disabled fieldset': {
                      borderColor: '#141414',
                      borderWidth: '1px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderWidth: '1px',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#a2a3a9',
                    },
                    '&.Mui-error fieldset': {
                      borderColor: '#d32f2f',
                    },
                  },
                }}
                value={displayName}
                onChange={(evt) => {
                  if (!evt.target.value) {
                    setDisplayNameError('Display name is required')
                  } else if (evt.target.value.length > 30) {
                    setDisplayNameError(
                      'Display name must be 30 characters or less'
                    )
                  } else {
                    setDisplayNameError(null)
                  }
                  setDisplayName(evt.target.value)
                }}
              />
            </Stack>
            <Stack spacing={1} direction="column">
              <div className={styles.TextFieldLabel}>Email</div>
              <TextField
                disabled={!editingActive}
                id="outlined-basic"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    color: '#a2a3a9',
                  },
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9 !important',
                    // border: '1px solid #a2a3a9 !important',
                  },
                  'input::placeholder': {
                    color: '#cac4d0 !important',
                    opacity: 1,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '2px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '2px',
                    },
                    '&:hover.Mui-disabled fieldset': {
                      borderColor: '#141414',
                      borderWidth: '1px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderWidth: '1px',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#a2a3a9',
                    },
                    '&.Mui-error fieldset': {
                      borderColor: '#d32f2f',
                    },
                  },
                }}
                value={email}
                onChange={(evt) => {
                  setEmail(evt.target.value)
                }}
              />
            </Stack>
            <Stack spacing={1} direction="column">
              <div className={styles.TextFieldLabel}>Phone Number</div>
              <TextField
                disabled={!editingActive}
                id="outlined-basic"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    color: '#a2a3a9',
                  },
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9 !important',
                    // border: '1px solid #a2a3a9 !important',
                  },
                  'input::placeholder': {
                    color: '#cac4d0 !important',
                    opacity: 1,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '2px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '2px',
                    },
                    '&:hover.Mui-disabled fieldset': {
                      borderColor: '#141414',
                      borderWidth: '1px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderWidth: '1px',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#a2a3a9',
                    },
                    '&.Mui-error fieldset': {
                      borderColor: '#d32f2f',
                    },
                  },
                }}
                value={phoneNumber}
                onChange={(evt) => {
                  setPhoneNumber(evt.target.value)
                }}
              />
            </Stack>
            {editingActive && (
              <Stack
                direction={{ xs: 'column' }}
                spacing={{ xs: 2 }}
                className={styles.SetupUserInfo}
              >
                <div className={styles.SetupUserInfoLabel}>
                  Upload a Profile Picture
                </div>
                <Stack
                  direction={'column'}
                  spacing={3}
                  alignItems={'center'}
                  alignSelf={'flex-start'}
                >
                  <div className={styles.SetupUserIconContainer}>
                    {profilePicFile ? (
                      <div>
                        <Close
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '100%',
                            color: '#f1f1f1',
                            cursor: 'pointer',
                          }}
                          onClick={() => setProfilePicFile(null)}
                        />
                        <img
                          src={URL.createObjectURL(profilePicFile)}
                          className={styles.SetupUserIcon}
                        />
                      </div>
                    ) : (
                      <AccountCircleIcon
                        className={styles.SetupUserIconPlaceholder}
                      />
                    )}
                  </div>
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      textTransform: 'capitalize',
                      height: '40px',
                      backgroundColor: '#c1feaf',
                      color: '#2e2e2e',
                    }}
                    onChange={(evt) => {
                      if (evt.target.files.length > 0) {
                        setProfilePicFile(evt.target.files[0])
                        setProfileChanged(true)
                      }
                    }}
                  >
                    Upload Image
                    <input type="file" accept="image/*" hidden />
                  </Button>
                </Stack>
              </Stack>
            )}

            <div
              className={styles.EditProfileButton}
              onClick={() => {
                if (editingActive && profileChanged) {
                  handleEditProfileSubmit()
                } else if (editingActive) {
                  setEditingActive(false)
                } else setEditingActive(true)
              }}
            >
              {editingActive ? 'Save' : 'Edit Profile'}
            </div>
          </Stack>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={submitEditError || displayNameError ? 'error' : 'success'}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {submitEditError
              ? submitEditError
              : displayNameError
              ? displayNameError
              : 'Profile updated!'}
          </Alert>
        </Snackbar>
      </>
    )
  }

  const UserFeedback = (props) => {
    const { userInfo } = props

    const [textValue, setTextValue] = useState('')
    const [openSnackBar, setOpenSnackBar] = React.useState(false)
    const [submitError, setSubmitError] = useState(null)

    const handleTextValueChange = (evt) => {
      setTextValue(evt.target.value)
    }

    const submitFeedback = async (textValue) => {
      console.log('submitFeedback entrance', { textValue })

      return await axiosInstance
        .post('/feedback/create', {
          feedback: {
            creatorEthAddress: userInfo?.ethAddress,
            textValue: textValue,
          },
        })
        .then(async function (response) {
          const { savedFeedback } = response.data
          console.log('submitFeedback response', savedFeedback)

          // clear text input?
          setTextValue('')
          setOpenSnackBar(true)
        })
        .catch(function (error) {
          setSubmitError('Error submitting feedback')
          setOpenSnackBar(true)
          console.log('submitFeedback', error)
        })
    }

    return (
      <Grid item xs={6}>
        <Stack spacing={2} direction={'column'} alignItems={'center'}>
          <TextField
            disabled={false}
            id="outlined-basic"
            variant="outlined"
            placeholder="Please enter your feedback here."
            multiline
            rows={4}
            fullWidth
            value={textValue}
            onChange={(evt) => handleTextValueChange(evt)}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
            }}
          />
          <Button
            onClick={() => {
              submitFeedback(textValue)
            }}
            variant="contained"
            sx={{
              mt: 1,
              color: 'rgba(46, 46, 46, 1)',
              backgroundColor: 'rgba(193, 254, 175, 1)',
              textTransform: 'capitalize',
              width: '25%',
            }}
          >
            Submit
          </Button>
        </Stack>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            onClose={() => setOpenSnackBar(false)}
            severity={submitError ? 'error' : 'success'}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {submitError ? submitError : 'Feedback submitted!'}
          </Alert>
        </Snackbar>
      </Grid>
    )
  }

  return (
    <>
      <Stack spacing={{ xs: 6 }} direction="column">
        <Stack spacing={{ xs: 2 }} direction="column">
          <div className={styles.SettingsHeader}>User Info</div>
          <UserInfo userInfo={userInfo} setCurrentUser={setCurrentUser} />
        </Stack>
        <Stack spacing={{ xs: 2 }} direction="column">
          <div className={styles.SettingsHeader}>Feedback</div>
          <UserFeedback userInfo={userInfo} />
        </Stack>
      </Stack>
    </>
  )
}

export default Settings

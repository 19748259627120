import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ImageIcon from '@mui/icons-material/Image'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import { TextField, InputAdornment, Button } from '@mui/material'
import { CustomSwitch } from './subcomponents/CustomSwitch'
import * as eventService from '../services/eventService'
import {
  BACKEND_URL,
  convertIntTo2DecimalFloat,
  EVENT_TYPES,
  getMyContributionAmount,
  getPrettyDateString,
} from '../utils/utils'
import styles from '../styles/ActiveEventComponent.module.css'
import { axiosInstance } from '../services/webClient'
import { useContext } from 'react'
import { ProviderAdapterContext } from '../App'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { FormattedEventUrl } from './subcomponents/FormattedEventUrl'
import AppsIcon from '@mui/icons-material/Apps'
import PersonIcon from '@mui/icons-material/Person'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import { useWeb3Provider } from '../services/web3Service'
import { broadcastRefundToBanktTeller } from '../web3/BanktTeller'

const EventHeaderComponent = (props) => {
  const { event, switchDisabled, switchOptedIn, switchOnClick } = props

  const creatorUserToEvent = event.userToEvents.find(
    (userToEvent) => userToEvent.isCreator
  )
  const creatorEthAddress = creatorUserToEvent.user.ethAddress
  const creatorUsername = creatorUserToEvent.user.displayName

  return (
    <Stack
      direction={'row'}
      spacing={{ xs: 2 }}
      className={styles.EventHeaderContainer}
    >
      <Stack
        direction={'row'}
        spacing={{ xs: 2 }}
        className={styles.EventHeaderIconContainer}
      >
        <AccountCircleIcon className={styles.EventHeaderIcon} />
        <Stack direction={'column'}>
          <div className={styles.EventHeader}>{event.name}</div>
          <div className={styles.EventSubheader}>
            Created by{' '}
            {creatorUsername
              ? creatorUsername
              : `${creatorEthAddress.substring(0, 8)}...`}
          </div>
        </Stack>
      </Stack>
      <div
        className={styles.EventSwitch}
        onClick={() => {
          // don't think this switch should do anything
          // if (!switchDisabled) switchOnClick(event)
        }}
      >
        <CustomSwitch disabled={switchDisabled} optedIn={switchOptedIn} />
      </div>
    </Stack>
  )
}

const PastAttendeeListItem = (props) => {
  const { attendee } = props

  return (
    <Stack
      direction={'row'}
      spacing={2}
      className={styles.EventAttendingListItem}
      style={{
        width: '100%',
      }}
    >
      <Stack
        direction={'row'}
        className={styles.EventAttendingListItemContainer}
        spacing={2}
      >
        {attendee.profilePic ? (
          <img
            src={
              'https://tippingpoint-staging-profile-pics.s3.us-east-2.amazonaws.com/' +
              attendee.id
            }
            className={styles.EventAttendingListItemImage}
          />
        ) : (
          <AccountCircleIcon
            className={styles.EventAttendingListItemImage}
            style={{ width: '44px', height: '44px' }}
          />
        )}
        <div className={styles.EventAttendingListItemName}>
          {attendee.user.displayName}
          {attendee.isCreator ? ' (Creator)' : ''} (
          {attendee.user.ethAddress.slice(0, 5) +
            '...' +
            attendee.user.ethAddress.slice(-3)}
          )
        </div>
      </Stack>
      <div className={styles.EventAttendingListItemAddr}>
        {attendee.optedIn ? (
          attendee.transferTransactionHash ? (
            <Link
              href={`https://${
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? ''
                  : 'sepolia.'
              }basescan.org/tx/${attendee.transferTransactionHash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Opted In
            </Link>
          ) : (
            'Opted In'
          )
        ) : (
          'Opted Out'
        )}
      </div>
    </Stack>
  )
}

const AttendeesListComponent = (props) => {
  const { numAttendees, attendees, tipped } = props

  const [expanded, setExpanded] = React.useState(false)

  return (
    <>
      <Stack direction={'column'} className={styles.EventAttendeesContainer}>
        <Stack direction={'row'} className={styles.EventAttendeesHeader}>
          <div className={styles.EventAttendeesHeaderText}>
            {tipped ? 'Attendees' : 'Opt-Ins'}
          </div>
          <div className={styles.EventAttendeesHeaderCount}>
            {numAttendees} {numAttendees !== 1 ? 'people' : 'person'}
          </div>
        </Stack>
        {
          <Accordion
            className={styles.EventAttendeesAccordion}
            expanded={expanded}
          >
            {!expanded && (
              <AccordionSummary>
                <Stack
                  direction={'row'}
                  className={styles.EventAttendeesAccordionHeader}
                  justifyContent={{ xs: 'space-between' }}
                  spacing={{ xs: 20 }}
                >
                  <div className={styles.EventAttendeesAccordionHeaderText}>
                    See Full List
                  </div>
                  <div
                    className={styles.EventAttendeesAccordionOpenButton}
                    onClick={() => setExpanded(true)}
                  >
                    OPEN
                  </div>
                </Stack>
              </AccordionSummary>
            )}
            <AccordionDetails>
              <Stack
                direction={'column'}
                spacing={{ xs: 1 }}
                className={styles.EventAttendeesAccordionDetails}
              >
                {/* <Stack
                  direction={'column'}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                > */}
                {attendees.map((ute) => {
                  return (
                    <PastAttendeeListItem attendee={ute} />
                    // <>
                    //   <div
                    //     className={styles.EventAttendeesAccordionDetailsName}
                    //     sx={{
                    //       display: 'flex',
                    //       direction: 'row',
                    //       justifyContent: 'space-between',
                    //     }}
                    //   >
                    //     {ute.user.displayName}
                    //   </div>
                    //   <div
                    //     className={styles.EventAttendeesAccordionDetailsName}
                    //   >
                    //     {ute.optedIn ? 'Opted In' : 'Opted Out'}
                    //     {ute.transferTransactionHash ? (
                    //       <Link
                    //         href={`https://${
                    //           process.env.REACT_APP_ENVIRONMENT ===
                    //           'production'
                    //             ? ''
                    //             : 'sepolia.'
                    //         }basescan.org/tx/${ute.transferTransactionHash}`}
                    //         target="_blank"
                    //         rel="noopener noreferrer"
                    //       >
                    //         Link
                    //       </Link>
                    //     ) : null}
                    //   </div>
                    // </>
                  )
                })}
                {/* </Stack> */}
                <div
                  className={styles.EventAttendeesAccordionDetailsCloseButton}
                  onClick={() => setExpanded(false)}
                >
                  CLOSE
                </div>
              </Stack>
            </AccordionDetails>
          </Accordion>
        }
      </Stack>
    </>
  )
}

export const PastEventPage = (props) => {
  const { currentEthAddress } = props
  let { eventId } = useParams()
  const [selectedEvent, setSelectedEvent] = useState(null)
  const { getProvider } = useWeb3Provider()
  const navigate = useNavigate()

  const isCreator = (activeEvent) => {
    const isCreator = activeEvent.creatorEthAddress === currentEthAddress
    return isCreator
  }

  const getMyOptIn = (event) => {
    const currentUserToEvent = event?.userToEvents.find(
      (userToEvent) => userToEvent.user.ethAddress === currentEthAddress
    )
    if (currentUserToEvent.isCreator) return true
    return currentUserToEvent ? currentUserToEvent.optedIn : false
  }


  const refundEvent = async (eventId, address, provider) => {

    await fetch(BACKEND_URL + '/event/refundEvent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventId: eventId,
        address: address,
      }),
      credentials: 'include',
    })
      .then(async function (response) {
        const { eip712RefundMessage } = await response.json()
        const refundApproval = eip712RefundMessage?.params[1]?.message
        console.log('refundEvent res ', { response, refundApproval })
        const refundSignature = await provider.request(eip712RefundMessage)
        await broadcastRefundToBanktTeller(refundSignature, provider, refundApproval)
      })
      .catch(function (error) {
        console.log('refundEvent', error)
      })
  }

  const eventHasPaidOut = React.useMemo(async () => {
    if (selectedEvent) {
      const hasEventPaidOut = await eventService.hasEventPaidOut(selectedEvent)
      return hasEventPaidOut
    }
  }, [selectedEvent])

  useEffect(() => {
    async function getSelectedEvent() {
      await eventService
        .getEvent(eventId, currentEthAddress)
        .then(async (event) => {
          setSelectedEvent(event)
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            setSelectedEvent(undefined)
          }
        })
    }
    getSelectedEvent()
  }, [])

  function calculateInviteeTransactionLink(selectedEvent, ethAddress) {
    const userToEvent = selectedEvent.userToEvents.find(
      (ute) => ute.user.ethAddress === ethAddress
    )
    if (!userToEvent.transferTransactionHash) return null
    if (process.env.NODE_ENV === 'production') {
      return `https://basescan.org/tx/${userToEvent.transferTransactionHash}`
    } else {
      return `https://sepolia.basescan.org/tx/${userToEvent.transferTransactionHash}`
    }
  }

  function getEventType(selectedEvent) {
    switch (selectedEvent.eventType) {
      case 'Dynamic':
        return 'Split Fixed Cost'
      case 'Static':
        return 'Fixed Cost Per Person'
      case 'General Fundraising':
        return 'Anything Helps'
      case 'Specific Fundraising':
        return 'Make or Break'
      default:
        return ''
    }
  }

  function getEventOutcome() {
    if (selectedEvent) {
      if (wasEventCanceled()) {
        return 'Campaign Cancelled'
      }
      if (eventTipped()) {
        return 'Campaign Tipped'
      }
      return 'Campaign Not Tipped'
    }
  }

  function wasEventCanceled() {
    return selectedEvent.status === 'CANCELLED'
  }

  function eventTipped() {
    return selectedEvent.status === 'TIPPED'
  }

  const EventDetailsDisplayComponent = (props) => {
    const { header, value, icon } = props

    return (
      <Stack
        direction={'column'}
        spacing={{ xs: 1 }}
        className={styles.EventDetailsSubContainer}
      >
        <div className={styles.EventDetailsText}>{header}</div>
        <TextField
          className={styles.EventDetailsTextField}
          disabled={true}
          value={value}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              '-webkit-text-fill-color': '#a2a3a9',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
          }}
        />
      </Stack>
    )
  }

  const EventDetailsComponent = (props) => {
    const { event, currentEthAddress } = props

    if (event.eventType === EVENT_TYPES.DYNAMIC) {
      return (
        <Stack
          direction={'column'}
          spacing={{ xs: 2 }}
          className={styles.EventDetailsContainer}
        >
          <EventDetailsDisplayComponent
            header={'Campaign Category'}
            value={'Split Fixed Cost'}
            icon={<AppsIcon className={styles.EventDetailsIcon} />}
          />
          <EventDetailsDisplayComponent
            header={'Minimum People'}
            value={event.minimumPeople ? event.minimumPeople : 0}
            icon={<PersonIcon className={styles.EventDetailsIcon} />}
          />
          {event.maximumPeople && (
            <EventDetailsDisplayComponent
              header={'Maximum People'}
              value={event.maximumPeople}
              icon={<AccessibilityIcon className={styles.EventDetailsIcon} />}
            />
          )}
          <EventDetailsDisplayComponent
            header={'Goal Amount'}
            value={`$${convertIntTo2DecimalFloat(event.goalAmount)}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        </Stack>
      )
    }
    if (event.eventType === EVENT_TYPES.STATIC) {
      return (
        <Stack
          direction={'column'}
          spacing={{ xs: 2 }}
          className={styles.EventDetailsContainer}
        >
          <EventDetailsDisplayComponent
            header={'Campaign Category'}
            value={'Fixed Price Per Person'}
            icon={<AppsIcon className={styles.EventDetailsIcon} />}
          />
          <EventDetailsDisplayComponent
            header={'Minimum People'}
            value={event.minimumPeople ? event.minimumPeople : 0}
            icon={<PersonIcon className={styles.EventDetailsIcon} />}
          />
          {event.maximumPeople && (
            <EventDetailsDisplayComponent
              header={'Maximum People'}
              value={event.maximumPeople ? event.maximumPeople : 0}
              icon={<AccessibilityIcon className={styles.EventDetailsIcon} />}
            />
          )}
          <EventDetailsDisplayComponent
            header={'Price per Person'}
            value={`$${convertIntTo2DecimalFloat(event.staticPricePerPerson)}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        </Stack>
      )
    }
    if (event.eventType === EVENT_TYPES.SPECIFIC_FUNDRAISING) {
      return (
        <Stack
          direction={'column'}
          spacing={{ xs: 2 }}
          className={styles.EventDetailsContainer}
        >
          <EventDetailsDisplayComponent
            header={'Campaign Category'}
            value={'Make or Break'}
            icon={<AppsIcon className={styles.EventDetailsIcon} />}
          />
          <EventDetailsDisplayComponent
            header={'Goal Amount'}
            value={`$${convertIntTo2DecimalFloat(event.goalAmount)}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        </Stack>
      )
    }
    if (event.eventType === EVENT_TYPES.GENERAL_FUNDRAISING) {
      return (
        <Stack
          direction={'column'}
          spacing={{ xs: 2 }}
          className={styles.EventDetailsContainer}
        >
          <EventDetailsDisplayComponent
            header={'Campaign Category'}
            value={'Anything Helps'}
            icon={<AppsIcon className={styles.EventDetailsIcon} />}
          />
        </Stack>
      )
    }
  }

  return (
    <>
      {selectedEvent && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack
            direction={'column'}
            spacing={{ xs: 2 }}
            alignItems={{ xs: 'center' }}
            className={styles.EventContainer}
          >
            <EventHeaderComponent
              event={selectedEvent}
              switchDisabled={isCreator(selectedEvent)}
              switchOptedIn={getMyOptIn(selectedEvent)}
              switchOnClick={() => {}}
            />
            {/* Event Image */}
            {selectedEvent.file && (
              <img
                src={selectedEvent.imgUrl}
                alt={selectedEvent.name}
                className={styles.EventImage}
              />
            )}
            {!selectedEvent.file && (
              <div className={styles.EventImageContainer}>
                <ImageIcon className={styles.EventImageIcon} />
              </div>
            )}
            <div className={styles.EventTippedContainer}>
              <Stack direction={'row'} justifyContent={{ xs: 'space-between' }}>
                <div
                  className={styles.EventTippedText}
                  style={{ color: '#829aff' }}
                >
                  {getEventOutcome(selectedEvent)}
                </div>
                <div className={styles.EventTippedDate}>
                  {wasEventCanceled()
                    ? ''
                    : getPrettyDateString(new Date(selectedEvent.deadline))}
                </div>
              </Stack>
            </div>
            <AttendeesListComponent
              numAttendees={selectedEvent.userToEvents.reduce(
                (acc, userToEvent) => (userToEvent.optedIn ? acc + 1 : acc),
                0
              )}
              attendees={selectedEvent.userToEvents.filter(
                (ute) => !!ute.isInvitee && !!ute.optedIn
              )}
              tipped={eventTipped()}
            />
            <Stack
              direction={'column'}
              className={styles.EventDescriptionContainer}
              spacing={{ xs: 1 }}
            >
              <div className={styles.EventDescriptionText}>Description</div>
              <TextField
                className={styles.EventDescriptionTextField}
                disabled={true}
                value={selectedEvent.description}
                multiline
                sx={{
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                    '&:hover .Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </Stack>
            <EventDetailsComponent
              event={selectedEvent}
              currentEthAddress={currentEthAddress}
            />
            <Stack
              direction={'column'}
              spacing={{ xs: 1 }}
              className={styles.EventPreferredCurrencyContainer}
            >
              <div className={styles.EventDetailsText}>
                {'Your Contribution'}
              </div>
              <TextField
                // className={styles.EventPreferredCurrencyTextField}
                disabled={true}
                variant="outlined"
                value={`${convertIntTo2DecimalFloat(
                  getMyContributionAmount(selectedEvent, currentEthAddress)
                )}`}
                // disabling the text field causes styles to be overridden
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    border: '1px solid #202020',
                    borderRadius: '5px',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {<AttachMoneyIcon className={styles.EventDetailsIcon} />}
                    </InputAdornment>
                  ),
                  endAdornment: calculateInviteeTransactionLink(
                    selectedEvent,
                    currentEthAddress
                  ) ? (
                    <>
                      <Link
                        href={calculateInviteeTransactionLink(
                          selectedEvent,
                          currentEthAddress
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        TX
                        <OpenInNewIcon
                          sx={{ width: '.85rem', height: '.85rem' }}
                        />
                      </Link>{' '}
                    </>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Stack>
            <Stack
              direction={'column'}
              spacing={{ xs: 1 }}
              className={styles.EventPreferredCurrencyContainer}
            >
              <div className={styles.EventDetailsText}>
                {'Total Money Raised'}
              </div>
              <TextField
                // className={styles.EventPreferredCurrencyTextField}
                disabled={true}
                variant="outlined"
                value={`${convertIntTo2DecimalFloat(
                  eventService.getTotalAmountRaised(selectedEvent)
                )}`}
                // disabling the text field causes styles to be overridden
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    border: '1px solid #202020',
                    borderRadius: '5px',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {<AttachMoneyIcon className={styles.EventDetailsIcon} />}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <FormattedEventUrl
              event={selectedEvent}
              containerClassName={styles.EventLinkContainer}
            />
            {isCreator(selectedEvent) ? (
              <Stack
                direction={'row'}
                spacing={{ xs: 2 }}
                className={styles.EventCancelEditContainer}
              >
                <Button
                  className={styles.EventCancelButton}
                  variant="outlined"
                  onClick={() => {
                    // eventService.cancelEvent(selectedEvent.id)
                    console.log('cancel event', selectedEvent)
                    navigate('/create', {
                      state: {
                        eventInputs: {
                          name: selectedEvent.name,
                          description: selectedEvent.description,
                          deadline: selectedEvent.deadline,
                          goalAmount: selectedEvent.goalAmount
                            ? convertIntTo2DecimalFloat(
                                selectedEvent.goalAmount
                              )
                            : null,
                          isCreatorAttendee: selectedEvent.isCreatorAttendee,
                          staticPricePerPerson:
                            selectedEvent.staticPricePerPerson,
                          minimumPeople: selectedEvent.minimumPeople,
                          maximumPeople: selectedEvent.maximumPeople,
                          eventType: selectedEvent.eventType,
                          file: selectedEvent.file,
                          imgUrl: selectedEvent.imgUrl,
                        },
                      },
                    })
                  }}
                >
                  Try Again
                </Button>
                {!!eventHasPaidOut && (
                  <Button
                    className={styles.EventReturnButton}
                    variant="outlined"
                    onClick={async () =>
                      refundEvent(selectedEvent.id, currentEthAddress, await getProvider())
                    }
                  >
                    Return Funds
                  </Button>
                )}
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
        </div>
      )}
    </>
  )
}

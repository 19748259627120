import { memo } from 'react';

const LanderVideo = () => {

    return (
        <video width={'100%'} height={'100%'} controls loop>
            <source src="https://s3.us-east-2.amazonaws.com/tipping-point-lander-video/video" type="video/mp4" />
        </video>
    )
}

export const LanderVideoComponent = memo(LanderVideo, (prevProps, nextProps) => { return true })
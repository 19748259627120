import { useNavigate } from 'react-router-dom'
import {
  Stack,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { CustomSwitch } from './CustomSwitch'
import {
  convertIntTo2DecimalFloat,
  getMyContributionAmount,
} from '../../utils/utils'
import styles from '../../styles/ActiveEventComponent.module.css'

const calculateHoursUntilDeadlineOccurs = (event) => {
  if (!event) return null
  const deadline = new Date(event.deadline)
  const now = new Date()
  const diffInMilliseconds = deadline - now
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
  return diffInHours
}

const EventHeaderComponent = (props) => {
  const { event, switchDisabled, switchOptedIn, switchOnClick } = props

  const getCreatorProfilePic = (event) => {
    if (!event) return null
    let ret = null
    const creatorUTE = event.userToEvents.find((userToEvent) => {
      return userToEvent.isCreator
    })
    if (creatorUTE.user.profilePic) {
      ret = `https://tippingpoint-staging-profile-pics.s3.us-east-2.amazonaws.com/${creatorUTE.user.id}`
    }
    return ret
  }

  return (
    <Stack
      direction={'row'}
      spacing={{ xs: 2 }}
      className={styles.EventHeaderContainer}
    >
      <Stack
        direction={'row'}
        spacing={{ xs: 2 }}
        className={styles.EventHeaderIconContainer}
      >
        {getCreatorProfilePic(event) ? 
        <img
        style={{
          width: '42px',
          height: '42px',
          borderRadius: '50%',
        }}
          src={getCreatorProfilePic(event)}
        /> : <AccountCircleIcon className={styles.EventHeaderIcon} />}
        <Stack direction={'column'}>
          <div className={styles.EventHeader}>{event.name}</div>
          <div className={styles.EventSubheader}>
            {calculateHoursUntilDeadlineOccurs(event)} hours left
          </div>
        </Stack>
      </Stack>
      <div
        className={styles.EventSwitch}
        onClick={() => {
          if (!switchDisabled(event)) switchOnClick(event)
        }}
      >
        <CustomSwitch
          disabled={switchDisabled(event)}
          optedIn={switchOptedIn(event)}
        />
      </div>
    </Stack>
  )
}



const ActiveEventComponent = (props) => {
  const {
    event,
    currentEthAddress,
    switchDisabled,
    switchOptedIn,
    switchOnClick,
  } = props


  const navigate = useNavigate()


  return (
    <>
      <div 
      className={styles.EventContainer}
      >
        <Stack
          direction={'column'}
          className={styles.EventSummaryContainer}
          spacing={{ xs: 2 }}
        >
          <EventHeaderComponent
            event={event}
            switchDisabled={switchDisabled}
            switchOptedIn={switchOptedIn}
            switchOnClick={switchOnClick}
          />
          <div className={styles.EventContributionAmount}>
            {`$${convertIntTo2DecimalFloat(
              getMyContributionAmount(event, currentEthAddress)
            )}`}
          </div>
          <div
            className={styles.EventViewDetailsButton}
            onClick={() => {
              navigate(`/event/${event.id}`)
            }}
          >
            VIEW DETAILS
          </div>
        </Stack>
      </div>
    </>
  )
}

export { ActiveEventComponent }

import * as React from 'react'
import Paper from '@mui/material/Paper'
import { axiosInstance } from '../services/webClient'
import Modal from '@mui/material/Modal'
import { EventInfo } from './subcomponents/EventInfo'
import {
  BACKEND_URL,
  EVENT_TYPES,
  NETWORKS,
  USDC_DECIMALS,
  convertIntTo2DecimalFloat,
} from '../utils/utils'
import { ActiveEventComponent } from './subcomponents/ActiveEventComponent'
import * as web3 from '../web3/web3'
import {
  Alert,
  Button,
  Snackbar,
  Stack,
  TextField,
  Checkbox,
  Divider,
  CircularProgress,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import CheckIcon from '@mui/icons-material/Check'
import { useEffect } from 'react'
import { useWeb3Provider } from '../services/web3Service'
import { set } from 'ip3country/src/ip_supalite'
import { usdc_round } from '../utils/utils'
import Tooltip from '@mui/material/Tooltip'
import { getCurrentAllowance } from '../web3/BanktTeller'

const ActiveEventsTable = (props) => {
  const [activeEvents, setActiveEvents] = React.useState([])
  // const [open, setOpen] = React.useState(false)
  const [selectedEvent, setSelectedEvent] = React.useState(null)

  const { getProvider } = useWeb3Provider()

  const { currentEthAddress, userInfo } = props

  React.useEffect(() => {
    if (window.history.state && window.history.state.usr?.eventCanceled) {
      setOpenSnackbar(true)
      window.history.replaceState(null, '')
    }
    if (!currentEthAddress) {
      console.log('callGetActiveEvents to be skipped', {
        address: currentEthAddress,
      })
      return
    }

    console.log('callGetActiveEvents about to be called', {
      address: currentEthAddress,
    })

    const getActiveEvents = async () => {
      await fetch(
        BACKEND_URL + '/user/' + currentEthAddress + '/activeEvents',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      )
        .then(async function (response) {
          const { activeEvents } = await response.json()
          console.log('getActiveEvents', activeEvents)
          setActiveEvents(activeEvents)
          return response
        })
        .catch(async function (err) {
          console.log('nick kjdfjn', err)
        })
    }
    getActiveEvents()
  }, [])

  const isCreator = (activeEvent) => {
    const isCreator = activeEvent.creatorEthAddress === props.currentEthAddress
    return isCreator
  }

  function updateSelectedEventOnActiveEvents(updatedEvent) {
    console.log('updateSelectedEvent1', { updatedEvent })
    setActiveEvents(
      activeEvents.map((event) =>
        event.id === updatedEvent.id ? updatedEvent : event
      )
    )
  }

  const isInvitee = (activeEvent) => !isCreator(activeEvent)

  const addUpOptedInAmounts = (accumulator, activeEvent) => {
    let total = accumulator
    activeEvent.userToEvents.forEach((userToEvent) => {
      if (userToEvent.user.ethAddress === currentEthAddress) {
        total += userToEvent.currentContribution
      }
    })
    return total
  }
  const isOptedIn = (activeEvent) => {
    let res = false
    activeEvent.userToEvents.forEach((userToEvent) => {
      if (
        userToEvent.user.ethAddress === currentEthAddress &&
        userToEvent.optedIn
      ) {
        res = true
      }
    })
    return res
  }
  const calculateOptInsAsInvitee = () => {
    const totalOptIns = activeEvents.filter(isInvitee).filter(isOptedIn).length
    return totalOptIns
  }
  function calculateTotalOptedInContributions() {
    return activeEvents
      .filter(isInvitee)
      .filter(isOptedIn)
      .reduce(addUpOptedInAmounts, 0)
  }

  const getSoonestEndingEvent = (events) => {
    if (!events || events?.length === 0) {
      return null
    }

    let soonestEvent = events[0]
    events.forEach((event) => {
      if (event?.deadline < soonestEvent?.deadline) {
        soonestEvent = event
      }
    })
    return soonestEvent
  }
  const calculateHoursUntilDeadlineOccurs = (event) => {
    if (!event) return null
    const deadline = new Date(event.deadline)
    const now = new Date()
    const diffInMilliseconds = deadline - now
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
    return diffInHours
  }
  const getMyOptIn = (event) => {
    const currentUserToEvent = event?.userToEvents.find(
      (userToEvent) => userToEvent.user.ethAddress === currentEthAddress
    )
    console.log('getMyOptIn', {
      currentUserToEvent: currentUserToEvent.optedIn,
    })
    if (currentUserToEvent.isCreator) return true
    return currentUserToEvent ? currentUserToEvent.optedIn : false
  }

  const [openOptInModal, setOptInModal] = React.useState(false)
  const [optIn, setOptIn] = React.useState(null)
  const handleopenOptInModal = () => setOptInModal(true)
  const handleCloseOptInModal = () => setOptInModal(false)

  const saveOptInDecision = async (contributionAmt, autoOptOutTarget) => {
    const optInResult = await web3.saveInviteeUserDecision(
      currentEthAddress,
      contributionAmt,
      autoOptOutTarget,
      selectedEvent.deadline,
      selectedEvent?.creatorEthAddress,
      optIn,
      selectedEvent.id,
      await getProvider()
    )
    const optInResultReturned =
      optInResult !== null && optInResult !== undefined
    if (optInResultReturned) {
      const updatedEvents = activeEvents.map((event) => {
        if (event.id === selectedEvent.id) {
          const updatedUtes = event.userToEvents.map((ute) => {
            if (ute.user.ethAddress === currentEthAddress) {
              return {
                ...ute,
                optedIn: optInResult,
              }
            }
            return ute
          })
          return { ...event, userToEvents: updatedUtes }
        }
        return event
      })
      setTimeout(() => {
        setActiveEvents(updatedEvents)
        setOptIn(null)
        setSelectedEvent(null)
        handleCloseOptInModal()
      }, 1000)
    } else {
      setTimeout(() => {
        handleCloseOptInModal()
      }, 1000)
    }
  }

  const setEvent = (event) => {
    const updatedActiveEvents = activeEvents.map((activeEvent) => {
      if (activeEvent.id === event.id) {
        return event
      }
      return activeEvent
    })
    setActiveEvents(updatedActiveEvents)
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '16px',
      }}
    >
      <div
        style={{
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '24px',
          color: 'rgba(193, 254, 175, 1)',
          margin: '15px 0px',
        }}
      >
        Active Campaigns
      </div>
      <Paper
        style={{
          width: '321px',
          height: '145px',
          color: 'rgba(255, 255, 255, 0.8)',
          boxShadow: '0px 11px 15px 0px rgba(0, 0, 0, 0.2)',
          marginBottom: '20px',
          textAlign: 'left',
          backgroundColor: 'rgba(255, 255, 255, 0.16)',
          padding: '10px',
        }}
      >
        <p>
          <b>Summary</b>
        </p>
        <div>
          You are currently opted into{' '}
          <b>{activeEvents?.length > 0 ? calculateOptInsAsInvitee() : 0}</b>{' '}
          Campaign(s)
        </div>
        <div>
          for $
          <b>
            {convertIntTo2DecimalFloat(
              activeEvents?.length > 0
                ? calculateTotalOptedInContributions()
                : 0
            )}
          </b>{' '}
          total
        </div>
        <br />
        <div>
          Next Campaign Deadline is:{' '}
          <b>{getSoonestEndingEvent(activeEvents)?.name}</b> ending in{' '}
          <b>
            {calculateHoursUntilDeadlineOccurs(
              getSoonestEndingEvent(activeEvents)
            )}
          </b>{' '}
          hours
        </div>
      </Paper>
      {activeEvents?.map((activeEvent) => (
        <ActiveEventComponent
          event={activeEvent}
          currentEthAddress={currentEthAddress}
          switchDisabled={(event) => {
            return isCreator(event)
          }}
          switchOptedIn={(event) => {
            return getMyOptIn(event)
          }}
          switchOnClick={(event) => {
            setSelectedEvent(event)
            setOptIn(!getMyOptIn(event))
            handleopenOptInModal()
          }}
        />
      ))}

      {selectedEvent && (
        <OptInModal
          event={selectedEvent}
          setEvent={setEvent}
          userInfo={userInfo}
          optIn={optIn}
          openOptInModal={openOptInModal}
          onClickCancel={handleCloseOptInModal}
          onClickConfirm={saveOptInDecision}
        />
      )}

      {/* <EventModal
        selectedEvent={selectedEvent}
        open={open}
        handleClose={() => {}}
        currentEthAddress={currentEthAddress}
        updateSelectedEvent={updateSelectedEventOnActiveEvents}
      /> */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnackbar(false)
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackbar(false)
          }}
          severity="success"
        >
          Campaign Canceled Successfully
        </Alert>
      </Snackbar>
    </div>
  )
}

const DynamicBudgetComponent = (props) => {
  const {
    event,
    stoplossValue,
    setStoplossValue,
    stoplossValueError,
    setStoplossValueError,
    stoploss,
    setStoploss,
  } = props

  const countOptIns = (event) => {
    return (
      event.userToEvents.filter((ute) => ute.optedIn).length +
      (event.isCreatorAttendee ? 1 : 0)
    )
  }

  const getDynamicContributionAmount = (event) => {
    const optedInCount = countOptIns(event) + 1
    if (optedInCount <= event.minimumPeople) {
      return event.expectedContributionAmount.max
    }
    return event.goalAmount / optedInCount
  }

  const [stoplossValueWarning, setStoplossValueWarning] = React.useState(null)

  return (
    <>
      <Stack direction={'column'} spacing={1}>
        <div
          style={{
            color: '#c1feaf',
            fontSize: '12px',
          }}
        >
          Budgeting
        </div>
        <Stack direction={'row'} alignItems={'center'}>
          <Checkbox
            checked={stoploss}
            onClick={() => {
              setStoploss(!stoploss)
              // const num = Number(stoplossValue)
              // const rounded = Number(
              //   convertIntTo2DecimalFloat(getDynamicContributionAmount(event))
              // )
              // if (!num || num <= 0) {
              //   setStoplossValueError('Please enter a valid number')
              // } else if (num < rounded) {
              //   setStoplossValueError(
              //     'Value cannot be less than the current contribution amount'
              //   )
              // }
              setStoplossValueWarning(null)
            }}
            size="small"
            sx={{
              color: '#a2a3a9',
              '&.Mui-checked': {
                color: '#c1feaf',
              },
            }}
          />

          <div
            style={{
              color: '#f1f1f1',
              fontSize: '16px',
              textWrap: 'nowrap',
            }}
          >
            Opt me out if the price goes above
          </div>
          <TextField
            sx={{
              color: '#a2a3a9',
              width: '70px',
              opacity: 1,
              marginLeft: '8px',
              '& .MuiInputBase-root': {
                padding: '0px 0px 0px 10px',
                '& .MuiInputBase-input': {
                  '-webkit-text-fill-color': '#f1f1f1 !important',
                  color: '#f1f1f1',
                  padding: '5px 0px',
                  // border: '1px solid #a2a3a9 !important',
                },
                '& input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                },
                '& input[type=number]': {
                  '-moz-appearance': 'textfield',
                },
                backgroundColor: '#2e2e2e',

                '& fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9 ',
                },
                '&.Mui-error fieldset': {
                  borderColor: 'red !important',
                },
              },
            }}
            error={stoplossValueError && stoploss ? true : false}
            id="expected-contribution-ammount"
            InputProps={{
              inputProps: {
                min: 0,
              },
              startAdornment: <div style={{ color: '#f1f1f1' }}>$</div>,
            }}
            value={stoplossValue}
            autoComplete="off"
            onChange={(evt) => {
              const rawSplit = String(evt.target.value)
                .split('.')
                .join('')
                .split(',')
                .join('')
              const lastChar = rawSplit.slice(-1)
              if (lastChar < '0' || lastChar > '9') {
                return
              }
              const newNum = Number(rawSplit) / 100
              if (newNum > 1000000) {
                return
              }
              const formatted = newNum.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                minimumIntegerDigits: 1,
              })
              setStoplossValue(formatted)
              const rounded = Number(
                convertIntTo2DecimalFloat(getDynamicContributionAmount(event))
              )
              if (newNum < rounded) {
                setStoplossValueError(
                  'Value cannot be less than the current contribution amount'
                )
              } else {
                setStoplossValueError(null)
              }
            }}
            onFocus={() => {
              setStoplossValueWarning(null)
            }}
            onBlur={() => {
              const num = Number(stoplossValue.split(',').join(''))
              if (
                num &&
                num * USDC_DECIMALS > event.expectedContributionAmount.max
              ) {
                setStoplossValueWarning(
                  `The maximum you could pay for this event is $${Number(
                    convertIntTo2DecimalFloat(
                      event.expectedContributionAmount.max
                    )
                  ).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    minimumIntegerDigits: 1,
                  })}`
                )
                setStoplossValue(
                  Number(
                    convertIntTo2DecimalFloat(
                      event.expectedContributionAmount.max
                    )
                  ).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    minimumIntegerDigits: 1,
                  })
                )
              } else if (!stoplossValueError) {
                setStoplossValueWarning(null)
              }
            }}
            aria-valuemin={0}
          />
        </Stack>
        {(stoplossValueError || stoplossValueWarning) && stoploss && (
          <div
            style={{
              color: stoplossValueError ? 'red' : '#a2a3a9',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            {stoplossValueError ? stoplossValueError : stoplossValueWarning}
          </div>
        )}
        <div
          style={{
            color: '#a2a3a9',
            fontSize: '14px',
            paddingTop: '10px',
          }}
        >
          As more people opt in, the price each person pays decreases. However,
          if someone opts out, the price increases. Automatically opt out if the
          price goes above the specified amount by checking the box above
        </div>
      </Stack>
    </>
  )
}

const FeesAndTotalComponent = (props) => {
  const {
    contributionAmt,
    contributionAmtError,
    eventType,
    stoploss,
    stoplossValue,
    stoplossValueError,
    maxDynamicPrice,
    staticPricePerPerson,
  } = props

  const [controlledStoplossValue, setControlledStoplossValue] =
    React.useState(null)
  const [controlledContributionAmt, setControlledContributionAmt] =
    React.useState(null)

  useEffect(() => {
    if (!stoplossValueError) {
      setControlledStoplossValue(stoplossValue.split(',').join(''))
    }
  }, [stoplossValue])

  useEffect(() => {
    if (!contributionAmtError) {
      setControlledContributionAmt(contributionAmt.split(',').join(''))
    }
  }, [contributionAmt])

  const computeOptInAmount = () => {
    if (eventType === 'Static') {
      return Number(staticPricePerPerson)
    } else if (eventType === 'Dynamic') {
      return stoploss
        ? Number(controlledStoplossValue) * USDC_DECIMALS
        : Number(maxDynamicPrice)
    } else {
      return Number(controlledContributionAmt) * USDC_DECIMALS
    }
  }

  const computeTippingPointFee = () => {
    const raw = computeOptInAmount() * 0.015
    const rounded = Math.round(raw / 10000)
    return rounded * 10000
  }

  const computeTotal = () => {
    return computeOptInAmount() + computeTippingPointFee()
  }

  return (
    <>
      <Stack direction={'column'} spacing={1}>
        <Divider
          sx={{
            backgroundColor: '#404040 !important',
            width: '100% !important',
          }}
        />
        <Stack direction={'row'} justifyContent={'space-between'}>
          <div style={{ color: '#a2a3a9', fontSize: '14px' }}>
            Opt-In Amount
          </div>
          <div>{`$${convertIntTo2DecimalFloat(computeOptInAmount())}`}</div>
        </Stack>
        <Divider
          sx={{
            backgroundColor: '#404040 !important',
            width: '100% !important',
          }}
        />
        <Stack direction={'row'} justifyContent={'space-between'}>
          <div style={{ color: '#a2a3a9', fontSize: '14px' }}>
            Tipping Point Fee (1.5%)
          </div>
          <div>{`$${convertIntTo2DecimalFloat(computeTippingPointFee())}`}</div>
        </Stack>
        <Divider
          sx={{
            backgroundColor: '#a2a3a9 !important',
            width: '100% !important',
          }}
        />
        <Stack direction={'row'} justifyContent={'space-between'}>
          <div style={{ color: '#a2a3a9', fontSize: '14px' }}>Total</div>
          <div>{`$${convertIntTo2DecimalFloat(computeTotal())}`}</div>
        </Stack>
      </Stack>
    </>
  )
}

const TwoStepConfirmComponent = (props) => {
  const {
    event,
    setEvent,
    allowance,
    currentEthAddress,
    stoploss,
    stoplossValue,
    contributionAmt,
    setInsufficentUSDC,
    setSnackbarOpen,
    setProgress,
    closeModal,
    validateInputs,
  } = props

  const { getProvider } = useWeb3Provider()

  const computeAutoOptOutTarget = () => {
    if (stoploss) {
      // todo: moose - confirm this returns the expected value for stop loss.
      return usdc_round(
        Number(stoplossValue.split(',').join('')) * USDC_DECIMALS
      )
    }
    return undefined
  }

  const computeEventAllowanceAmount = () => {
    if (event.eventType === 'Static') {
      return usdc_round(event.staticPricePerPerson * 1.015)
    } else if (event.eventType === 'Dynamic' && stoploss) {
      return usdc_round(
        Number(stoplossValue.split(',').join('')) * USDC_DECIMALS * 1.015
      )
    } else if (event.eventType === 'Dynamic') {
      // return usdc_round(event.expectedContributionAmount.max * 1.015)
      // TODO: this logic is duplicated in computeTotal(). Refactor.
      const tippingPointFeeRaw = event.expectedContributionAmount.max * 0.015
      const tippingPointFeeRounded =
        Math.round(tippingPointFeeRaw / 10_000) * 10_000
      return event.expectedContributionAmount.max + tippingPointFeeRounded
    } else {
      return usdc_round(
        Number(contributionAmt.split(',').join('')) * USDC_DECIMALS * 1.015
      )
    }
  }

  const computeContributionAmount = () => {
    if (event.eventType === 'Static') {
      //moose fix the value here
      return event.staticPricePerPerson
    } else if (event.eventType === 'Dynamic' && stoploss) {
      return usdc_round(
        Number(stoplossValue.split(',').join('')) * USDC_DECIMALS
      )
    } else if (event.eventType === 'Dynamic') {
      // return usdc_round(event.expectedContributionAmount.max)
      return event.expectedContributionAmount.max
    } else {
      return usdc_round(
        Number(contributionAmt.split(',').join('')) * USDC_DECIMALS
      )
    }
  }

  const allowanceTooLow = async () => {
    const provider = await getProvider()
    if (!currentEthAddress) {
      console.error('allowanceTooLow: currentEthAddress is undefined')
    }
    let currentInviteeAllowance = await getCurrentAllowance(
      currentEthAddress,
      provider
    )
    const eventAllowance = computeEventAllowanceAmount()
    console.log('allowanceTooLow?', {
      currentAllowance: currentInviteeAllowance,
      eventAllowance,
    })
    // todo moose - update the allowance value here with
    // the new endpoint - '/:ethAddress/optinTotals'
    // return eventAllowance > currentInviteeAllowance
    return true
  }

  const [stepOne, setStepOne] = React.useState(allowanceTooLow())
  const [stepOneLoading, setStepOneLoading] = React.useState(false)
  const [stepTwoLoading, setStepTwoLoading] = React.useState(false)
  const [done, setDone] = React.useState(false)

  useEffect(() => {
    setStepOne(allowanceTooLow())
  }, [stoploss, stoplossValue, contributionAmt])

  const updateUTE = () => {
    const updatedUTES = event.userToEvents.map((ute) => {
      if (ute.user.ethAddress === currentEthAddress) {
        return {
          ...ute,
          optedIn: true,
        }
      }
      return ute
    })
    setEvent({ ...event, userToEvents: updatedUTES })
  }

  const doAllowanceRequest = async () => {
    if (!validateInputs()) {
      setSnackbarOpen(true)
      return
    }

    setStepOneLoading(true)
    // compute allowance amount
    const provider = await getProvider()
    let currentAllowance = await getCurrentAllowance(
      currentEthAddress,
      provider
    )
    const newAllowance =
      Number(currentAllowance) + computeEventAllowanceAmount()

    // request allowance increase
    try {
      const { signatureAllowanceUSDC, allowanceTX } =
        await web3.completeAllowanceRequest(
          currentEthAddress,
          newAllowance,
          event.deadline,
          await getProvider()
        )
      console.log('doAllowanceRequest', { signatureAllowanceUSDC, allowanceTX })
      // allowance set successfully
      setInsufficentUSDC(false)
      setStepOneLoading(false)
      setStepOne(false)
    } catch (error) {
      if (error.message === 'Insufficient USDC tokens') {
        setInsufficentUSDC(true)
        setSnackbarOpen(true)
      } else {
        console.error('doAllowanceRequest error', JSON.stringify(error))
      }
      setStepOneLoading(false)
    }
  }

  const doTransactionRequest = async () => {
    if (!validateInputs()) {
      setSnackbarOpen(true)
      return
    }

    setStepTwoLoading(true)

    // get contribution amount
    const contributionAmount = computeContributionAmount()
    console.log('doTransactionRequest', { contributionAmount })
    // request transaction signing
    try {
      await web3.completeTransactionRequest(
        currentEthAddress,
        event.creatorEthAddress,
        contributionAmount,
        computeAutoOptOutTarget(),
        event.id,
        event.deadline,
        await getProvider()
      )

      // transaction signed successfully
      updateUTE()
      setStepTwoLoading(false)
      setDone(true)
      setProgress('done')
      setTimeout(() => {
        closeModal()
        setProgress('')
      }, 1000)
    } catch (error) {
      console.error('doTransactionRequest error', JSON.stringify(error))
    }
  }

  return (
    <>
      <Stack direction="row" spacing={1} height={'100px'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              backgroundColor: '#c1feaf',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              position: 'relative',
              top: '25%',
              transform: 'translateY(-50%)',
              color: '#1a1a1a',
              border: '1px solid #f1f1f1',
              zIndex: 1,
            }}
          >
            {stepOne ? (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                1
              </div>
            ) : (
              <CheckIcon
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}
          </div>
          <div
            style={{
              position: 'absolute',
              width: '10px',
              height: '12px',
              top: '44px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#c1feaf',
              borderLeft: '1px solid #f1f1f1',
              borderRight: '1px solid #f1f1f1',
              zIndex: 2,
            }}
          />
          <div
            style={{
              position: 'absolute',
              width: '10px',
              height: '12px',
              top: '56px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: stepOne ? '#1a1a1a' : '#c1feaf',
              borderLeft: '1px solid #f1f1f1',
              borderRight: '1px solid #f1f1f1',
              zIndex: 2,
            }}
          />
          <div
            style={{
              backgroundColor: stepOne ? '#1a1a1a' : '#c1feaf',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              position: 'absolute',
              top: '75%',
              transform: 'translateY(-50%)',
              border: '1px solid #f1f1f1',
              zIndex: 1,
            }}
          >
            {!done ? (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: stepOne ? '#f1f1f1' : '#1a1a1a',
                }}
              >
                2
              </div>
            ) : (
              <CheckIcon
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: stepOne ? '#f1f1f1' : '#1a1a1a',
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '150px',
            position: 'relative',
          }}
        >
          <div
            style={{
              padding: '10px 0px',
              textAlign: 'center',
              width: '100%',
              borderRadius: '8px',
              backgroundColor: stepOne ? '#c1feaf' : '#1a1a1a',
              color: stepOne ? '#1a1a1a' : '#a2a3a9',
              cursor: stepOne ? 'pointer' : 'default',
              position: 'absolute',
              top: '25%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '14.5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (stepOne) {
                doAllowanceRequest()
              }
            }}
          >
            {stepOneLoading ? (
              <CircularProgress
                size={20}
                sx={{
                  color: '#1a1a1a',
                  padding: 0,
                }}
              />
            ) : (
              <>
                Approve Allowance
                <Tooltip
                  title="This allowance should cover all your opted-in events for Tipping Point to dispense funds"
                  arrow
                >
                  <HelpIcon
                    style={{
                      color: '#a2a3a9',
                      position: 'relative',
                      left: '.5px',
                      scale: '.5',
                      cursor: 'help',
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
          <div
            style={{
              padding: '10px 0px',
              textAlign: 'center',
              width: '100%',
              borderRadius: '8px',
              backgroundColor: !stepOne ? '#c1feaf' : '#1a1a1a',
              color: !stepOne ? '#1a1a1a' : '#a2a3a9',
              cursor: !stepOne ? 'pointer' : 'not-allowed',
              position: 'absolute',
              top: '75%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (!stepOne && !done) {
                doTransactionRequest()
              }
            }}
          >
            {stepOneLoading ? (
              <CircularProgress
                size={20}
                sx={{
                  color: '#1a1a1a',
                  padding: 0,
                }}
              />
            ) : (
              <>
                Contribute
                <Tooltip
                  title="This authorizes the transfer details for the Tipping Point smart contract"
                  arrow
                >
                  <HelpIcon
                    style={{
                      color: '#a2a3a9',
                      position: 'relative',
                      left: '1px',
                      scale: '.5',
                      cursor: 'help',
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </Stack>
    </>
  )
}

const OptOutButton = (props) => {
  const { currentEthAddress, event, setEvent, setProgress, closeModal } = props

  const [loading, setLoading] = React.useState(false)

  const updateUTE = () => {
    const updatedUTES = event.userToEvents.map((ute) => {
      if (ute.user.ethAddress === currentEthAddress) {
        return {
          ...ute,
          optedIn: false,
        }
      }
      return ute
    })
    setEvent({ ...event, userToEvents: updatedUTES })
  }

  const doOptOut = async () => {
    setLoading(true)

    try {
      // opt out
      await web3.optIn(false, currentEthAddress, event.id)

      // opted out successfully
      updateUTE()
      setProgress('done')
      setLoading(false)
      setTimeout(() => {
        closeModal()
        setProgress('')
      }, 1000)
    } catch (error) {
      console.error('doOptOut error', error)
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        padding: '10px 0px',
        textAlign: 'center',
        width: '40%',
        borderRadius: '8px',
        backgroundColor: '#c1feaf',
        color: '#1a1a1a',
        cursor: 'pointer',
        margin: '10px 0px',
      }}
      onClick={() => {
        doOptOut()
      }}
    >
      {loading ? (
        <CircularProgress
          size={20}
          sx={{
            color: '#1a1a1a',
            padding: 0,
          }}
        />
      ) : (
        'Opt-Out'
      )}
    </div>
  )
}

export const OptInModal = (props) => {
  const { event, setEvent, userInfo, optIn, openOptInModal, onClickCancel } =
    props

  const [snackbarOpen, setSnackbarOpen] = React.useState(false)

  const countOptIns = (event) => {
    return (
      event.userToEvents.filter((ute) => ute.optedIn).length +
      (event.isCreatorAttendee ? 1 : 0)
    )
  }

  const getDynamicContributionAmount = (event) => {
    const optedInCount = countOptIns(event) + 1
    console.log('getDynamicContributionAmount', { optedInCount })
    if (optedInCount <= event.minimumPeople) {
      return event.expectedContributionAmount.max
    }
    if (optedInCount >= event.maximumPeople) {
      return event.expectedContributionAmount.min
    }
    return event.goalAmount / optedInCount
  }

  const [contributionAmt, setContributionAmt] = React.useState(
    event.eventType === 'Static'
      ? convertIntTo2DecimalFloat(event.staticPricePerPerson)
      : event.eventType === 'Dynamic'
      ? convertIntTo2DecimalFloat(getDynamicContributionAmount(event))
      : convertIntTo2DecimalFloat(0)
  )

  const [stoplossValue, setStoplossValue] = React.useState(
    event.eventType === 'Dynamic'
      ? convertIntTo2DecimalFloat(event.expectedContributionAmount.max)
      : ''
  )
  const [stoploss, setStoploss] = React.useState(false)
  const [stoplossValueError, setStoplossValueError] = React.useState(null)
  const [contributionAmtError, setContributionAmtError] = React.useState(null)
  const [insufficentUSDC, setInsufficentUSDC] = React.useState(false)

  const [progress, setProgress] = React.useState('')

  const displayValueToUSDC = (value) => {
    const num = Number(value.split(',').join(''))
    if (isNaN(num)) {
      return null
    }
    return usdc_round(num * USDC_DECIMALS)
  }

  const validateInputs = () => {
    if (event.eventType === 'Static') {
      return true
    }
    if (event.eventType === 'Dynamic') {
      // user set a stoploss, check if it's valid
      if (stoploss && !displayValueToUSDC(stoplossValue)) {
        setStoplossValueError('Please enter a valid number')
        return false
      }
      if (
        stoploss &&
        displayValueToUSDC(stoplossValue) < getDynamicContributionAmount(event)
      ) {
        setStoplossValueError('Value cannot be less than opt-in amount')
        return false
      }
    }

    // fundraising event, check if contribution amount is valid
    if (!contributionAmt || !displayValueToUSDC(contributionAmt)) {
      setContributionAmtError('Please enter a valid number')
      return false
    }

    return true
  }

  return (
    <>
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'visible',
        }}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={openOptInModal}
        onClose={onClickCancel}
      >
        <div
          style={{
            backgroundColor: '#000000',
            borderRadius: '16px',
            border: 'solid 1px',
            height: 'auto',
            width: '400px',
            padding: '15px 10px',
            overflow: 'visible',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#c1feaf',
          }}
        >
          <h2>Confirm your {optIn ? 'Opt-In' : 'Opt-Out'} choice</h2>
          {optIn ? (
            <Stack direction={'column'} width={'90%'} spacing={1}>
              <div
                style={{
                  color: '#c1feaf',
                  fontSize: '12px',
                }}
              >
                Contribution Amount
              </div>
              <TextField
                sx={{
                  color: '#a2a3a9',
                  opacity: 1,
                  '& .MuiInputBase-root': {
                    '& .MuiInputBase-input': {
                      '-webkit-text-fill-color': '#f1f1f1 !important',
                      // border: '1px solid #a2a3a9 !important',
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                    },
                    '& input[type=number]::-webkit-outer-spin-button': {
                      '-webkit-appearance': 'none',
                    },
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield',
                    },
                    backgroundColor: '#2e2e2e',

                    '& fieldset': {
                      borderColor: '#a2a3a9',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#a2a3a9 !important',
                    },
                    '&.Mui-error fieldset': {
                      borderColor: 'red !important',
                    },
                    '&.Mui-error.Mui-focused fieldset': {
                      borderColor: 'red !important',
                    },
                  },
                }}
                id="expected-contribution-ammount"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  startAdornment: <div style={{ color: '#f1f1f1' }}>$</div>,
                }}
                disabled={
                  event.eventType === 'Static' || event.eventType === 'Dynamic'
                }
                error={contributionAmtError ? true : false}
                value={contributionAmt}
                autoComplete="off"
                // type="number"
                aria-valuemin={0}
                onChange={(evt) => {
                  const rawSplit = String(evt.target.value)
                    .split('.')
                    .join('')
                    .split(',')
                    .join('')
                  const lastChar = rawSplit.slice(-1)
                  if (lastChar < '0' || lastChar > '9') {
                    return
                  }
                  const newNum = Number(rawSplit) / 100
                  if (newNum > 1000000) {
                    return
                  }
                  const formatted = newNum.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    minimumIntegerDigits: 1,
                  })
                  setContributionAmt(formatted)
                  setContributionAmtError(null)
                }}
                helperText={contributionAmtError}
                onBlur={() => {
                  validateInputs()
                }}
              />
              {event.eventType === 'Dynamic' &&
                countOptIns(event) >= event.minimumPeople && (
                  <DynamicBudgetComponent
                    event={event}
                    stoplossValue={stoplossValue}
                    setStoplossValue={setStoplossValue}
                    stoplossValueError={stoplossValueError}
                    setStoplossValueError={setStoplossValueError}
                    stoploss={stoploss}
                    setStoploss={setStoploss}
                  />
                )}

              <FeesAndTotalComponent
                contributionAmt={contributionAmt}
                contributionAmtError={contributionAmtError}
                eventType={event.eventType}
                stoploss={stoploss}
                stoplossValue={stoplossValue}
                stoplossValueError={stoplossValueError}
                maxDynamicPrice={event.expectedContributionAmount.max}
                staticPricePerPerson={event.staticPricePerPerson}
                allowance={userInfo.allowance}
              />
            </Stack>
          ) : (
            <></>
          )}
          {progress === 'done' ? (
            <CheckIcon />
          ) : (
            <>
              {optIn ? (
                <TwoStepConfirmComponent
                  event={event}
                  setEvent={setEvent}
                  allowance={userInfo.allowance}
                  currentEthAddress={userInfo.ethAddress}
                  stoploss={stoploss}
                  stoplossValue={stoplossValue}
                  contributionAmt={contributionAmt}
                  setInsufficentUSDC={setInsufficentUSDC}
                  setSnackbarOpen={setSnackbarOpen}
                  setProgress={setProgress}
                  closeModal={onClickCancel}
                  validateInputs={validateInputs}
                />
              ) : (
                <OptOutButton
                  currentEthAddress={userInfo.ethAddress}
                  event={event}
                  setEvent={setEvent}
                  setProgress={setProgress}
                  closeModal={onClickCancel}
                />
              )}
            </>
          )}
        </div>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error">
          {stoplossValueError
            ? stoplossValueError
            : contributionAmtError
            ? contributionAmtError
            : insufficentUSDC
            ? 'Insufficient USDC tokens'
            : 'Something went wrong'}
        </Alert>
      </Snackbar>
    </>
  )
}

const EventModal = (props) => {
  const {
    currentEthAddress,
    selectedEvent,
    open,
    handleClose,
    updateSelectedEvent,
  } = props
  const [optedIn, setOptedIn] = React.useState(false)

  React.useEffect(() => {
    console.log('Event Modal entry', { selectedEvent })
    const userOnEvent = selectedEvent?.userToEvents.find((userToEvent) => {
      return userToEvent.user.ethAddress === currentEthAddress
    })
    setOptedIn(userOnEvent?.optedIn || false)
  }, [])

  React.useEffect(() => {
    console.log('useEffect selectedEvent', { selectedEvent })
    const userOnEvent = selectedEvent?.userToEvents.find((userToEvent) => {
      return userToEvent.user.ethAddress === currentEthAddress
    })
    setOptedIn(userOnEvent?.optedIn || false)
  }, [selectedEvent, currentEthAddress])

  return (
    <>
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            // width: '50vw',
            height: '85vh',
            maxWidth: '500px',
            background: 'white',
            padding: '15px 10px',
            overflow: 'auto',
          }}
        >
          <EventInfo
            selectedEvent={selectedEvent}
            optedIn={optedIn}
            setOptedIn={setOptedIn}
            currentEthAddress={currentEthAddress}
            updateSelectedEvent={updateSelectedEvent}
          />
        </div>
      </Modal>
    </>
  )
}
export default ActiveEventsTable
export { EventModal }

// import Footer from '../subcomponents/Footer'
import React, { useEffect, useState } from 'react'
import { Stack, Grid, Button, List, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material'
import { ExpandMore, ExpandLess} from '@mui/icons-material'
import vector from '../../assets/Vector.png'
import rightArrow from '../../assets/RightArrow.png'
import styles from '../../styles/FAQ.module.css'
import { FooterType1, FooterType2, FooterType3 } from '../subcomponents/Footer'
import { useNavigate } from 'react-router-dom'


/*

TODO: 

*/

function VectorQuestionDropdown(props) {


    const { heading, body } = props

    const formatBody = () => {
        return body.map((item, index) => {
            return (
                <div key={index} className={styles.VectorQuestionDropdownBodyLine}>
                    {item}
                </div>
            )
        })
    }

    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            <List className={styles.VectorQuestionDropdownBox} >
                <ListItemButton onClick={handleClick} className={styles.VectorQuestionDropdownButton}>
                    <ListItemIcon>
                        <img src={vector} className={styles.VectorQuestionDropdownIcon}/>
                    </ListItemIcon>
                    <ListItemText disableTypography primary={<div className={styles.VectorQuestionDropdownHeading}>{heading}</div>}/>
                    {open? <ExpandLess sx={{color: 'rgba(193, 254, 175, 1)', width: '16px', height: '16px'}}/> : <ExpandMore sx={{color: 'rgba(193, 254, 175, 1)', width: '16px', height: '16px'}}/>}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <div className={styles.VectorQuestionDropdownBody}>
                        {formatBody()}
                    </div>
                </Collapse>
            </List>
        </>
    )
}



function VectorQuestionBit(props) {

    const { header, body } = props

    const formatBody = () => {
        return body.map((item, index) => {
            return (
                <div key={index} className={styles.VectorQuestionDropdownBodyLine}>
                    {item}
                </div>
            )
        })
    }

    return (
        <>
           <Stack spacing={{xs:0.5, md: 2}} direction={"row"} alignItems={'flex-start'}>
                <img src={vector} alt='vector' className={styles.VectorQuestionBitIcon}/>
                <Stack spacing={{xs:0.5, md: 2}} direction={"column"} sx={{padding: 0}}
                    alignItems={"flex-start"} textAlign={"left"}
                    paddingTop={'5px'} color={"rgba(215, 215, 215, 1)"}>
                    <h3 className={styles.VectorQuestionBitHeading}>{header}</h3>
                    <div className={styles.VectorQuestionBitBody}>
                        {formatBody()}
                    </div>
                </Stack>
            </Stack> 
        </>
    )
}

function ContactUsComponent() {


    return (
        <>
            <Grid container  spacing={{xs:5, md: 4, lg: 15}} >
                <Grid item xs={12} lg={6}>
                    <Stack direction={'column'} spacing={{xs:2, md: 3}} xs={12} lg={6} className={styles.ContactUsComponentTopStack}>
                        <div className={styles.ContactUsComponenth2}> - Sub headline</div>
                        <div className={styles.ContactUsComponenth1}>Have Any Questions?</div>
                        <div className={styles.ContactUsComponenth3}>Need help with something? Here are our most frequently asked questions.</div>
                        <Button variant='contained' className={styles.ContactUsComponentButton}>
                            <Stack direction={"row"} alignItems={'center'} spacing={{xs:0,md:1}}>
                                <div className={styles.ContactUsComponentButtonText}>Contact Us</div>
                                <img src={rightArrow} alt='rightArrow' className={styles.ContactUsComponentButtonIcon}/>
                            </Stack>
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={6} >
                    <Stack direction={"column"} spacing={{xs:1.5}} width={'100%'}>
                        {/* <VectorQuestionDropdown />
                        <VectorQuestionDropdown />
                        <VectorQuestionDropdown />
                        <VectorQuestionDropdown />
                        <VectorQuestionDropdown /> */}
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

function FAQPage() {

    const navigate = useNavigate()

    return (
        <>
            <Grid
                container
                spacing={{xs: 2, md: 6}}
                justifyContent={'center'}
            >
                <Grid item xs={12}>
                    <Stack direction={'column'} spacing={{md: 0}} alignItems={'center'}>
                        <div className={styles.FAQHeader}>Platform</div>
                        <Grid container  spacing={{xs: 3, md: 4, lg: 6}}>
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={{xs: 3, md: 4, lg: 6}}>
                                    <Grid item xs={12} sx={{height: 'auto'}}>
                                        <VectorQuestionBit
                                            header={'Is there a Tipping Point app I can download?'}
                                            body={['Tipping Point is not currently available through the Apple/Google store, ' + 
                                            'but you can save the app to your phone or tablet home screen by clicking the menu ' + 
                                            'in the top-right corner of your browser window and selecting “Install” or “Add to Home Screen."']}    
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VectorQuestionBit
                                            header={'What personal information does Tipping Point collect from its users?'}
                                            body={['Tipping Point doesn\'t require any personal or financial information in order to create an account or use the platform.',
                                            'Once you\'ve connected your wallet, simply set a Display Name and you\'re ready to start using Tipping Point!',
                                            '(Providing a phone number and email address are optional, and will only be used to send users notifications or updates regarding Tipping Point.)'
                                        ]}    
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={{xs: 3, md: 4, lg: 6}}>
                                    <Grid item xs={12}>
                                        <VectorQuestionBit
                                            header={'How do I contact Tipping Point customer support?'} 
                                            body={['For questions, comments, troubleshooting, or bug/error reports please send us an email at support@tippingpoint.app.',
                                            ]}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VectorQuestionBit
                                            header={'How is Tipping Point different from other crowdfunding platforms?'}
                                            body={['Tipping Point stands apart from other crowdfunding platforms in several important aspects:',
                                            'Tipping Point helps you plan and organize your Campaigns prior to collecting any money, so your invitees only contribute once the project is guaranteed to have the support it needs to occur.',
                                            'Once your Campaign has tipped, you receive your funds instantly - no more waiting days for payments to clear or the bank to open.',
                                            'Tipping Point has the lowest fees of any crowdfunding platform: a flat 1.5%. And users only pay fees when the Campaign they’re contributing to tips.',
                                            'Because it’s built on web3, Tipping Point has no access to user funds, cannot censor user transactions on the network, and cannot deplatform users from accessing the Tipping Point platform.'
                                        ]}    
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={{xs: 3, md: 4, lg: 6}}>
                        <Grid item xs={12} lg={6}>
                            <Stack direction={"column"} alignItems={"flex-start"} spacing={{xs: 2, md: 4}}>
                                <Stack direction={"column"} width={'100%'} alignItems={'flex-start'}>
                                    <div className={styles.FAQItem2Header}>Events & Fundraising</div>
                                    <Stack direction={"column"} spacing={{xs:1.5, md: 2}} width={'100%'}>
                                    {/* <VectorQuestionDropdown 
                                        heading={'Is Tipping Point only for actual in-person events?'} 
                                        body={['No, Tipping Point is a general crowdfunding platform that allows users to raise money for any possible reason.',
                                        'We simply use the word "Event" to refer to Tipping Point funding requests, rather than constantly repeating a phrase like "projects, events, campaigns, businesses or fundraisers."',
                                        'Regardless of your goal, if you need to raise money Tipping Point can help you do it!'
                                    ]}/> */}
                                    <VectorQuestionDropdown 
                                        heading={'Does Tipping Point require KYC/AML information from its users?'} 
                                        body={['No, Tipping Point does not collect any financial information from its users. All you need to use Tipping Point is an Ethereum wallet.'
                                    ]}/>
                                    <VectorQuestionDropdown 
                                        heading={'Have your smart contracts been audited?'} 
                                        body={['Yes! Our smart contracts have been audited by leading security firm Trail of Bits.'
                                    ]}/>
                                    <VectorQuestionDropdown 
                                        heading={'Does Tipping Point issue tax or business forms for the money users collect?'} 
                                        body={['No, Tipping Point simply provides a tool that enables users to collect money from their network.', 
                                        'Any financial or tax reporting related to users\' fundraising activities will need to be completed by the users themselves - this is outside the scope of Tipping Point\'s functionality.'
                                    ]}/>
                                    <VectorQuestionDropdown
                                        heading={'What are the differences between Campaign types?'}
                                        body={[<div>Please visit our <span 
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            navigate('/howitworks')
                                        }}
                                        style={{
                                            color: '#c1feaf',
                                            cursor: 'pointer'
                                        }}>How It Works </span>page for a full description and examples of Campaign types.</div>]}
                                    />
                                    <VectorQuestionDropdown
                                        heading={'Are there minimum requirements for fundraisers or contributions?'}
                                        body={[
                                            'Yes, Tipping Point requires a minimum goal of $50 to create an Campaign and the minimum attendee contribution is $10.',
                                        ]}
                                    />
                                    </Stack>
                                </Stack>
                                <Stack direction={"column"} width={'100%'} alignItems={'flex-start'}>
                                    <div className={styles.FAQItem2Header}>Accounts & Wallets</div>
                                    <Stack direction={"column"} spacing={{xs:1.5, md: 2}} width={'100%'}>
                                        <VectorQuestionDropdown
                                            heading={'How do I set up an account?'}
                                            body={[
                                                <div>Please see our <span style={{
                                                    color: '#c1feaf',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    window.scrollTo(0, 0)
                                                    navigate('/getstarted')
                                                }}>Get Started</span> page for instructions on how to create a wallet and fund it with $USDC.</div>
                                            ]}
                                        />
                                        <VectorQuestionDropdown
                                            heading={'What do I do if I don\'t have a web3 wallet?'}
                                            body={[
                                                'Users can create a web3 wallet instantly and for free.',
                                                <div>Tipping Point will work with a variety of wallets, but visit our <span style={{
                                                    color: '#c1feaf',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    window.scrollTo(0, 0)
                                                    navigate('/getstarted')
                                                }}>Get Started</span> page to learn about setting up our recommended Coinbase Wallet.</div>
                                            ]}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack direction={"column"} width={'100%'} alignItems={'flex-start'}>
                                    <div className={styles.FAQItem2Header}>Refunds</div>
                                    <Stack direction={"column"} spacing={{xs:1.5, md: 2}} width={'100%'}>
                                        <VectorQuestionDropdown
                                            heading={'How do I request a refund?'}
                                            body={[
                                                'If the Campaign hasn\'t tipped yet, you can cancel a pending contribution by opting-out of the Campaign.',
                                                'Once an Campaign tips, attendee contributions are immediately transferred to the Campaign Creator.',
                                                'Because Tipping Point never has access to user funds, Creators of tipped Campaigns are the only ones who can refund contributions to Campaign Attendees.',
                                                'Refunds must be issued within 30 days of the Campaign tipping, otherwise Creators will have to refund attendees outside of the Tipping Point platform.'
                                            ]}
                                        />
                                        <VectorQuestionDropdown
                                            heading={'An Campaign I participated in was refunded, but I didn\'t receive back my original amount. Why?'}
                                            body={[
                                                'When an Campaign is refunded, Attendees receive back their full contribution, but the 1.5% Tipping Point fee is returned as an account credit instead, which will be automatically deducted from the fee for the next Campaign that user contributes to.',
                                            ]}
                                        />
                                        <VectorQuestionDropdown
                                            heading={'I\'m trying to refund an Campaign, but my wallet is telling me I need some ETH in order to pay the transaction fee.'}
                                            body={[
                                                'Yes, currently all transaction fees on Ethereum must be paid in the blockchain\'s native token, ETH.',
                                                'In order to refund your Campaign\'s contributors, Creators must have the relevant amount of $USDC in their wallet as well as a small amount of ETH (typically less than $1 worth) to submit the transaction to the network.',
                                                'Users can acquire ETH from a variety of sources, such as exchanges like Coinbase or Kraken.'
                                            ]}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Stack direction={"column"} alignItems={"flex-start"}>
                                <div className={styles.FAQItem2Header}>Fees & Payments</div>
                                <Stack direction={"column"} spacing={{xs:1.5, md: 2}} width={'100%'}>
                                    <VectorQuestionDropdown
                                        heading={'What fees does Tipping Point charge?'}
                                        body={[
                                            'Tipping Point charges a flat 1.5% fee on all funds collected through successful ("tipped") Campaigns.',
                                            'If an Campaign fails to tip, no user funds are moved and no fees are charged.'
                                        ]}
                                    />
                                    <VectorQuestionDropdown
                                        heading={'Do I need money in my wallet to use Tipping Point?'}
                                        body={[
                                            'Campaign Creators do not need to have any funds in their wallet in order to create an Campaign and collect money.',
                                            'Contributors need to have USDC in their wallet in order to opt-in/contribute to Tipping Point Campaigns. However, ' + 
                                            'the money will only be transferred from their wallet if the Campaign successfully tips.'
                                        ]}
                                    />
                                    <VectorQuestionDropdown
                                        heading={'What currencies can I use with Tipping Point?'}
                                        body={[
                                            'Currently, Tipping Point only works with USDC. However, we have plans to expand our currency selection soon: first, ' + 
                                            'additional stablecoins (for euros and other non-USD currencies) and eventually all ERC-20 tokens.'
                                        ]}
                                    />
                                    <VectorQuestionDropdown
                                        heading={'Can I use regular dollars (USD) with Tipping Point?'}
                                        body={[
                                            'No, Tipping Point is built on Ethereum and uses tokens like USDC on the network to transfer funds between users. However,' + 
                                            ' it is fast and easy (and often free) to convert your USDC 1-to-1 to USD through an exchange like Coinbase.'
                                        ]}
                                    />
                                    {/* <VectorQuestionDropdown
                                        heading={'You said the allowance fee was a one-time charge; why am I being asked to pay it again?'}
                                        body={[
                                            'When Tipping Point users first contribute to an Campaign, they must approve a transaction on the Ethereum network allowing the Tipping Point smart contract to move funds stored within their wallet. This allowance is set by default (by your wallet) to the amount the user wishes to contribute to that first Campaign.',
                                            'Any amount higher than that will require an updated approval (and thus an additional allowance transaction). These additional approvals can be avoided by setting a higher allowance during the initial approval.',
                                            'For example, if a user\'s first contribution is $100, and they set $100 as their allowance limit, they will be able to contribute any amount up to $100 in future Tipping Point Campaigns without ever having to update their allowance.',
                                            'If they eventually decide to contribute more than $100 to another Campaign, their wallet will prompt them to update their allowance amount, which requires an additional Ethereum transaction/fee.', 
                                            'If the user instead set their allowance to, say, $500 in that first allowance (even though they\'re only contributing $100), they can make Tipping Point contributions up to $500 per event across any number of Campaigns.',
                                            'In summary, by setting an allowance higher than any amount they might ever contribute on Tipping Point, users can avoid paying any allowance fees after the first one.'
                                        ]}
                                    /> */}
                                    <VectorQuestionDropdown
                                        heading={'Does Tipping Point charge for fundraisers that don\'t reach their minimum requirements?'}
                                        body={[
                                            'No, Tipping Point only charges fees for successful/tipped Campaigns.'
                                        ]}
                                    />
                                    <VectorQuestionDropdown
                                        heading={'How long will it take to receive my funds?'}
                                        body={[
                                            'Creators receive funds instantly once their Campaign tips.'
                                        ]}
                                    />
                                    <VectorQuestionDropdown
                                        heading={'What payment methods are accepted?'}
                                        body={[
                                            'Tipping Point users must have a web3 connected wallet with a $USDC balance in order to opt-in to Campaigns and contribute funds. Creators of successful Campaigns receive payouts in $USDC as well.',
                                            <div>Click <span
                                                style={{
                                                    color: '#c1feaf',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    window.open('https://www.circle.com/en/usdc', '_blank')
                                                }}
                                            >here</span> to learn more about $USDC, or <span
                                                style={{
                                                    color: '#c1feaf',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    window.open('https://www.circle.com/en/usdc/ecosystem#get-usdc', '_blank')
                                                }}
                                            >here</span> to learn more about adding $USDC to your wallet.</div>
                                        ]}
                                    />
                                    <VectorQuestionDropdown
                                        heading={'How do I get $USDC in my wallet?'}
                                        body={[
                                            'Users can exchange USD for $USDC instantly and for free through their Coinbase account and then transfer to their wallet. Other exchanges also allow for USD-to-$USDC conversions, although they may charge additional fees.'
                                        ]}
                                    />
                                    <VectorQuestionDropdown
                                        heading={'How do I swap my $USDC for USD?'}
                                        body={[
                                            'The easiest way to convert $USDC to actual USD is to cash out through an exchange like Coinbase. Their conversion is free and instant, and users can even skip the conversion and cash $USDC out directly to their bank account.'
                                        ]}
                                    />
                                    <VectorQuestionDropdown
                                        heading={'Do I have to pay Ethereum or Base blockchain transaction fees to use Tipping Point?'}
                                        body={[
                                            'No, Tipping Point pays the blockchain fees for normal platform operations (e.g. when attendees opt-in/contribute to an Event or when Creators collect money through Campaigns).',
                                            'The only time these fees are paid by users is when a Creator refunds an Campaign (the Creator must pay the gas fee).'
                                        ]}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FooterType1 />
                </Grid>
            </Grid>
        </>
    )
}

export default FAQPage

export { VectorQuestionDropdown }
import { Stack } from '@mui/material'
import styles from '../../styles/HowItWorks.module.css'
import { FooterType1 } from '../subcomponents/Footer'
import { useState, useEffect } from 'react'

function HowItWorks() {

  const [ width, setWidth ] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  },[])

  return (
    <>

    <Stack direction='column' spacing={{md: 2}}>
      <div className={styles.HowItWorksHeader}>
        How It Works
      </div>
      <div className={styles.HowItWorksText}>
        <span className={styles.HowItWorksTextLine} style={{color: '#f1f1f1'}}>
          Tipping Point is an app that helps you collect money from people to plan group projects or campaigns.
        </span>
        <span className={styles.HowItWorksTextLine}>
          Tipping Point is different from other crowdfunding services because Tipping Point 
          fundraisers only collect money from contributors if the project or campaign is guaranteed to occur.
        </span>
        <span className={styles.HowItWorksTextLine}>
          Campaign creators set certain requirements (like a minimum amount of money to raise 
          and/or a minimum number of contributors) along with a deadline for invitees to opt-in.
        </span>
        <span className={styles.HowItWorksTextLine}>
          If the requirements are met before the deadline, the Campaign "tips" and Tipping Point 
          automatically transfers funds from those who opted-in to the Campaign creator. Tipping 
          Point takes a small fee (1.5%) for this service.
        </span>
        <span className={styles.HowItWorksTextLine}>
          If the requirements aren't met before the deadline, nothing happens and no fees are charged.
        </span>
      </div>
      <div className={styles.HowItWorksSubHeader}>
        Tipping Point Campaigns
      </div>
      <div className={styles.HowItWorksText}>
        <span className={styles.HowItWorksTextLine}>
          There are four types of Tipping Point Campaigns, based on the requirements creators want to set.
        </span>
        <span className={styles.HowItWorksTextLine}>
          The first two, Split Fixed Cost and Fixed Price Per Person, result in every attendee paying the same price. 
          The number of contributors is often capped, e.g. for in-person campaigns.
        </span>
        <span className={styles.HowItWorksTextLine}>
          The latter two, Make or Break and Anything Helps, are fundraising options where invitees can 
          contribute whatever they'd like, and any number of contributors can participate.
        </span>
        <span className={styles.HowItWorksListHeader}>
          <span className={styles.HowItWorksListHeaderAlt}>1. </span>Split Fixed Cost Campaigns are for when you need a specific amount of money, regardless of the number of contributors.
        </span>
        <span style={{paddingLeft: width > 1199 ? '20px' : '0px'}} className={styles.HowItWorksTextLine}>
          The final price per person will change depending on how many invitees opt in. 
          As more people join, the cost per person goes down.
        </span>
        <span style={{paddingLeft: width > 1199 ? '20px' : '0px'}} className={styles.HowItWorksTextList}>
          Examples:
        </span>
        <ul>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Booking a vacation home to share with friends
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Renting a venue (like a restaurant, ballroom, or community center) for a party or campaign
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Group activities like boat rentals, taxis, or party busses where the companies charge a flat group rate
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextLine}>
              Crowdfunding a project with a predefined startup cost, like a business venture or construction project
            </span>
          </li>
        </ul>
        <span className={styles.HowItWorksListHeader}>
          <span className={styles.HowItWorksListHeaderAlt}>2. </span>Fixed Price Per Person Campaigns are for when the price per person is set from the start, 
          and you just need to ensure a minimum number of people participate.
        </span>
        <span style={{paddingLeft: width > 1199 ? '20px' : '0px'}} className={styles.HowItWorksTextList}>
          Examples:
        </span>
        <ul>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Buying or selling tickets to a concert, performance, or sporting campaign (venues
              can ensure a profitable attendance; contributors can book rows or sections with friends)
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Hiring a chef or caterer for a group campaign (there's often a set price per guest, but a 
              minimum number of guests required)
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextLine}>
              Booking people like tour guides, tutors, or yoga teachers where the instructor charges per person
            </span>
          </li>
        </ul>
        <span className={styles.HowItWorksListHeader}>
          <span className={styles.HowItWorksListHeaderAlt}>3. </span>Make or Break Campaigns are for when you 
          need to raise at least a minimum amount of money the project to happen.
        </span>
        <span style={{paddingLeft: width > 1199 ? '20px' : '0px'}} className={styles.HowItWorksTextLine}>
          Make or Break Campaigns can still raise more than that, but won't tip unless they reach their minimum.
        </span>
        <span style={{paddingLeft: width > 1199 ? '20px' : '0px'}} className={styles.HowItWorksTextList}>
          Examples:
        </span>
        <ul>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Funding a construction project
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Starting a small business or launching a product
            </span>     
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Producing a film, album, or other creative work
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextLine}>
              Funding scientific research projects or expeditions
            </span>
          </li>
        </ul>
        <span className={styles.HowItWorksListHeader}>
          <span className={styles.HowItWorksListHeaderAlt}>4. </span>Anything Helps Campaigns have 
          no minimums - the campaign will tip as long as there's at least one contribution.
        </span>
        <span style={{paddingLeft: width > 1199 ? '20px' : '0px'}} className={styles.HowItWorksTextList}>
          Examples:
        </span>
        <ul>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Crowdfunding medical expenses
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Fundraising for disaster relief/humanitarian aid
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Collecting donations for charities and non-profits
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextList}>
              Funding campaigns for personal projects or creative works with no fixed budget
            </span>
          </li>
          <li>
            <span style={{paddingLeft: '0px'}} className={styles.HowItWorksTextLine}>
              Crowdfunding things like honeymoons, adoption fees, etc.
            </span>
          </li>
        </ul>
        <div className={styles.HowItWorksSubHeader}>
          Web3
        </div>
        <div className={styles.HowItWorksText}>
          <span className={styles.HowItWorksTextLine}>
            Tipping Point uses a series of smart contracts on the Ethereum blockchain to transfer 
            funds between users, providing several important web3 benefits:
          </span>
          <span className={styles.HowItWorksTextLine}>
            <span style={{color: '#f1f1f1'}}>Seizure Resistance</span>: Funds are always self-custodied by users in their wallets. Tipping Point 
            smart contracts merely route contributions from contributors to campaign creators when an campaign tips; 
            Tipping Point never takes possession of user funds.
          </span>
          <span className={styles.HowItWorksTextLine}>
            <span style={{color: '#f1f1f1'}}>Censorship Resistance and Privacy</span>: Tipping Point doesn't require any personal or financial information 
            to set up an account and has no way of stopping a transaction once it's been submitted to the Ethereum network.
          </span>
          <span className={styles.HowItWorksTextLine}>
            Users can simply connect their wallets and start using Tipping Point - no personal data or permission required.
          </span>
          <span className={styles.HowItWorksTextLine}>
            <span style={{color: '#f1f1f1'}}>Data Security</span>: Because Tipping Point doesn't require users to provide sensitive data, that information is never 
            at risk of being abused or stolen.
          </span>
          <span className={styles.HowItWorksTextLine}>
            Phone numbers and email addresses are optional, and only used to enhance the Tipping Point experience 
            (users can receive text and email notifications; find friends on the app more easily; opt-in to Tipping 
            Point communications; etc.).
          </span>
          <span className={styles.HowItWorksTextLine}>
            <span style={{color: '#f1f1f1'}}>Lightning-fast Transactions</span>: Tipping Point eliminates financial intermediaries (like banks, credit card 
            companies, and payment processors) and instead connects users directly to their peers on the blockchain.
          </span>
          <span className={styles.HowItWorksTextLine}>
            Like Ethereum, Tipping Point runs 24/7/365 - no more waiting days for payments to settle or for the bank 
            to open. Once a Campaign tips, creators can collect their funds immediately.
          </span>
          <span className={styles.HowItWorksTextLine}>
            <span style={{color: '#f1f1f1'}}>Lowest Fees</span>: By cutting out the middlemen, Tipping Point can offer the lowest crowdfunding fees out there: 
            a mere 1.5%. And users only pay fees for Campaigns that tip - no risk, all reward.
          </span>
        </div>

      </div>
    </Stack>
    <FooterType1/>
    </>
  )
}

export default HowItWorks

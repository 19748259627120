import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { axiosInstance } from '../services/webClient'
import * as eventService from '../services/eventService'
import { EventInfo } from './subcomponents/EventInfo'
import {
  BACKEND_URL,
  convertIntTo2DecimalFloat,
  getMyContributionAmount,
  getMyCreatorStatus, 
  getMyOptInStatus,
} from '../utils/utils'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormLabel from '@mui/material/FormLabel'
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import styles from '../styles/EventHistoryTable.module.css'
import { Grid, InputAdornment, TextField } from '@mui/material'
import { fontSize } from '@mui/system'

export default function EventHistoryTable(props) {
  const [open, setOpen] = React.useState(false)
  const [pastEvents, setPastEvents] = React.useState([])
  const [searchResults, setSearchResults] = React.useState([])
  const [searchingActive, setSearchingActive] = React.useState(false)
  const [ searchValue, setSearchValue ] = React.useState('')

  const [selectedEvent, setSelectedEvent] = React.useState(null)
  const [sortDirections, setSortDirections] = React.useState([ false, false, false ])

  const [ tippedFilter, setTippedFilter ] = React.useState(false)
  const [ untippedFilter, setUntippedFilter ] = React.useState(false)
  const [ createdFilter, setCreatedFilter ] = React.useState(false)
  const [ attendedFilter, setAttendedFilter ] = React.useState(false)

  const [width, setWidth] = React.useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  },[])

  const navigate = useNavigate()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { currentEthAddress } = props

  React.useEffect(() => {
    if (!currentEthAddress) {
      console.log('callGetInactiveEvents to be skipped', {
        address: currentEthAddress,
      })

      return
    }

    console.log('callGetInactiveEvents about to be called', {
      address: currentEthAddress,
    })
    const callGetInactiveEvents = async () => {
      await fetch(BACKEND_URL + '/user/' + currentEthAddress + '/inactiveEvents', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })
        .then(async function (response) {
          const { inactiveEvents } = await response.json()
          console.log('successfully fetched inactive events', inactiveEvents)
          setPastEvents(inactiveEvents)
        })
        .catch(async function (err) {
          console.log('error', err)
        })
    }
    callGetInactiveEvents()
  }, [])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgba(26, 26, 26, 1)',
      color: 'rgba(133, 133, 133, 1)',
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(43, 43, 43, 1)', //theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleSearchSubmit = () => {
    if (searchValue === '') {
      setSearchingActive(false)
      return
    }
    setSortDirections([false, false, false])
    setSearchingActive(true)
    const searchResults = pastEvents.filter((event) => {
      console.log('searchValue', searchValue)
      let match = event.name.toLowerCase().includes(searchValue.toLowerCase())
      if (getEventCreatorDisplayName(event)) {
        match = match || getEventCreatorDisplayName(event).toLowerCase().includes(searchValue.toLowerCase())
      }
      return match
    })
    setSearchResults(searchResults)
  }

  const handleTableCellClick = (args) => {
    const newSortDirections = sortDirections.map((direction, index) => {
      if (index === args.id) {
        return !direction
      }
      return direction
    })
    setSortDirections(newSortDirections)

    /* Sorting Logic */
    if (args.id === 0) {
      const sortCallback = (a, b) => {
        if (a.name < b.name) {
          return newSortDirections[0] ? -1 : 1
        }
        if (a.name > b.name) {
          return newSortDirections[0] ? 1 : -1
        }
        return 0
      }
      if (searchingActive) {
        setSearchResults([...searchResults].sort(sortCallback))
      } else {
        setPastEvents([...pastEvents].sort(sortCallback))
      }
    }
    if (args.id === 1) {
      const sortCallback = (a, b) => {
        if (a.creatorEthAddress < b.creatorEthAddress) {
          return newSortDirections[1] ? -1 : 1
        }
        if (a.creatorEthAddress > b.creatorEthAddress) {
          return newSortDirections[1] ? 1 : -1
        }
        return 0
      }
      if (searchingActive) {
        setSearchResults([...searchResults].sort(sortCallback))
      } else {
        setPastEvents([...pastEvents].sort(sortCallback))
      }
    }
    if (args.id === 2) {
      const sortCallback = (a, b) => {
        if (getMyContributionAmount(a) < getMyContributionAmount(b)) {
          return newSortDirections[2] ? -1 : 1
        }
        if (getMyContributionAmount(a) > getMyContributionAmount(b)) {
          return newSortDirections[2] ? 1 : -1
        }
        return 0
      }
      if (searchingActive) {
        setSearchResults([...searchResults].sort(sortCallback))
      } else {
        setPastEvents([...pastEvents].sort(sortCallback))
      }
    }

    console.log('handleTableCellClick', newSortDirections)
  }

  const eventSatisfiesCurrentFilters = (event) => {
    const tipped = eventService.hasMetTippingPointRequirements(event)
    return (
      (!tippedFilter || tipped) &&
      (!untippedFilter || !tipped) &&
      (!createdFilter || getMyCreatorStatus(event, currentEthAddress)) &&
      (!attendedFilter || getMyOptInStatus(event, currentEthAddress))
    )
  }

  const getEventCreatorDisplayName = (event) => {
    return event.userToEvents.find((userToEvent) => {
      return userToEvent.isCreator
    }).user.displayName
  }

  const getFormatedDisplayName = (displayName) => {
    if (width < 600) {
      return displayName.length < 12 ? displayName : displayName.substring(0, 9) + '...'
    }
    return displayName
  }

  return (
    <>
      <Stack direction='column' spacing={{xs: 2}}>
        <div
        className={styles.EventHistoryHeader}
        >
          Campaign History
        </div>
        <TextField className={styles.EventHistorySearchBar}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              handleSearchSubmit(evt.target.value)
            }
          }}
          variant='outlined'
          placeholder={'Search by Keyword'}
          value={searchValue}
          onChange={(evt) => {setSearchValue(evt.target.value)}}
          InputProps={{
            classes: { input: styles.EventHistorySearchInput },
            endAdornment: !searchingActive ? <SearchIcon 
              onClick={(evt) => {handleSearchSubmit(evt.target.value)}}
              
              className={styles.EventHistorySearchIcon} /> :
              <CloseIcon
              onClick={(evt) => {
                setSortDirections([false, false, false])
                setSearchingActive(false)
                setSearchValue('')
              }}
              className={styles.EventHistorySearchIcon} />,
          }}
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: '#333333', borderRadius: '10px',
            },
            '& .MuiInputBase-input': {
              color: '#cac4d0',
            },
            'input::placeholder': {
              color: '#cac4d0',
              opacity: 1,
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: '#cac4d0',
              }
            }
          }}
        >

        </TextField>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FilterAltIcon />
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={6} md={3}>
            <FormControlLabel
              sx={{
                color: '#a2a3a9',
              }}
                control={
                  <Checkbox /*sx={{color: '#a2a3a9'}}*/ checked={tippedFilter} onClick={() => {
                    {/* Doesn't make sense to have both tipped and untipped filters active */}
                     if (untippedFilter && !tippedFilter) {
                      setUntippedFilter(false)
                    }
                    setTippedFilter(!tippedFilter)
                  }} name="gilad" />
                }
                label="Tipped"
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControlLabel
                sx={{
                  color: '#a2a3a9',
                }}
                control={
                  <Checkbox /*sx={{color: '#a2a3a9'}}*/ checked={untippedFilter} onClick={() => {
                    if (tippedFilter && !untippedFilter) {
                      setTippedFilter(false)
                    }
                    setUntippedFilter(!untippedFilter)
                  }} name="jason" />
                }
                label="Untipped"
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControlLabel
                sx={{
                  color: '#a2a3a9',
                }}
                control={
                  <Checkbox /*sx={{color: '#a2a3a9'}}*/ checked={createdFilter} onClick={() => {setCreatedFilter(!createdFilter)}} name="gilad" />
                }
                label="Created"
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControlLabel
                sx={{
                  color: '#a2a3a9',
                }}
                control={
                  <Checkbox /*sx={{color: '#a2a3a9'}}*/ checked={attendedFilter} onClick={() => {setAttendedFilter(!attendedFilter)}} name="jason" />
                }
                label="Attended"
              />
            </Grid>
          </Grid>
          {/* <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            {/* <FormLabel component="legend">Assign responsibility</FormLabel> 
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox checked={tippedFilter} onClick={() => {
                    {/* Doesn't make sense to have both tipped and untipped filters active 
                     if (untippedFilter && !tippedFilter) {
                      setUntippedFilter(false)
                    }
                    setTippedFilter(!tippedFilter)
                  }} name="gilad" />
                }
                label="Tipped"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={untippedFilter} onClick={() => {
                    if (tippedFilter && !untippedFilter) {
                      setTippedFilter(false)
                    }
                    setUntippedFilter(!untippedFilter)
                  }} name="jason" />
                }
                label="Untipped"
              />
            </FormGroup>
          </FormControl>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard"> 
            {/* <FormLabel component="legend">Assign responsibility</FormLabel>  
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox checked={createdFilter} onClick={() => {setCreatedFilter(!createdFilter)}} name="gilad" />
                }
                label="Created"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={attendedFilter} onClick={() => {setAttendedFilter(!attendedFilter)}} name="jason" />
                }
                label="Attended"
              />
            </FormGroup>
          </FormControl> */}
        </div> 
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <EventModal
            selectedEvent={selectedEvent}
            open={open}
            handleClose={handleClose}
            currentEthAddress={currentEthAddress}
            updateSelectedEvent={setSelectedEvent}
          />

          <TableContainer component={Paper} sx={{ width: '90%', backgroundColor: '#2e2e2e' }}>
            <Table
              sx={{ backgroundColor: 'rgb(46, 46, 46)', margin: 0 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <Stack spacing={{xs: 0}}  direction={'row'} className={styles.EventHistoryTableHeader}>
                    <Stack spacing={1}  direction={'row'} alignItems={'center'} sx={{xs: {width: '40%'}, md: {}}}
                      className={styles.EventHistoryHeaderCell}
                    >
                      <div className={styles.EventHistoryTableColumnText}>Campaign</div>
                       <FilterListIcon
                       onClick={(evt) => {handleTableCellClick({ id: 0 })}} className={!sortDirections[0] ? styles.TableCellIcon : styles.TableCellIconFlipped}/>
                    </Stack>
                    <Stack spacing={1}  direction={'row'} alignItems={'center'}
                      className={styles.EventHistoryHeaderCell}
                    >
                      <div className={styles.EventHistoryTableColumnText}>Creator</div>
                       <FilterListIcon
                       onClick={(evt) => {handleTableCellClick({ id: 1 })}} className={!sortDirections[1] ? styles.TableCellIcon : styles.TableCellIconFlipped}/>
                    </Stack>
                    <Stack spacing={1}  direction={'row'} alignItems={'center'}
                      className={styles.EventHistoryHeaderCell}
                    >
                      <div className={styles.EventHistoryTableColumnText}>Amount Raised</div>
                       <FilterListIcon
                       onClick={(evt) => {handleTableCellClick({ id: 2 })}} className={!sortDirections[2] ? styles.TableCellIcon : styles.TableCellIconFlipped}/>
                    </Stack>
                  </Stack>
                </TableRow>
              </TableHead>
              <TableBody>
                  {!searchingActive && pastEvents?.map((pastEvent, idx) => {
                    if (!eventSatisfiesCurrentFilters(pastEvent)) {
                      return null
                    }
                    return (
                      <Stack direction={'row'} spacing={0} key={idx} hover sx={{width: '100%'}}
                        className={idx % 2== 0 ? styles.EventHistoryRowEven : styles.EventHistoryRowOdd}
                        onClick={() => navigate(`/pastEvent/${pastEvent.id}`)}
                      >
                        <div className={styles.EventHistoryRowName} >
                          {pastEvent.name}
                        </div>
                        <div className={styles.EventHistoryRowCreator}>
                          {`${
                            getFormatedDisplayName(getEventCreatorDisplayName(pastEvent))
                            }`}
                        </div>
                        <div className={styles.EventHistoryRowAmount}>
                          {`$${convertIntTo2DecimalFloat(
                            eventService.getTotalAmountRaised(pastEvent)
                          )}`}
                        </div>
                      </Stack>
                    )
                  })}
                  {searchingActive && searchResults?.map((pastEvent, idx) => {
                    if (!eventSatisfiesCurrentFilters(pastEvent)) {
                      return null
                    }
                    return (
                      <Stack direction={'row'} spacing={1} key={idx} hover
                        className={idx % 2== 0 ? styles.EventHistoryRowEven : styles.EventHistoryRowOdd}
                        onClick={() => navigate(`/pastEvent/${pastEvent.id}`)}
                      >
                        <div className={styles.EventHistoryRowName} >
                          {pastEvent.name}
                        </div>
                        <div className={styles.EventHistoryRowCreator}>
                          {`${
                            getFormatedDisplayName(getEventCreatorDisplayName(pastEvent))
                            }`}
                        </div>
                        <div className={styles.EventHistoryRowAmount}>
                          {`$${convertIntTo2DecimalFloat(
                            getMyContributionAmount(pastEvent)
                          )}`}
                        </div>
                      </Stack>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Stack direction="row" 
        spacing={1}
          className={styles.EventHistoryDownloadContainer}
        >
            <DownloadIcon className={styles.EventHistoryDownloadIcon} />
            <div className={styles.EventHistoryDownloadText}>Export Campaign History</div>
        </Stack>
        <Button className={styles.EventHistoryActiveButton} 
          onClick={() => navigate('/active')}
          >
          View My Active Campaigns
        </Button> 
        <Button className={styles.EventHistoryCreateButton}
          variant='outlined'
          onClick={() => navigate('/create')}>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <AddIcon className={styles.EventHistoryCreateIcon} />
            <div>Create Another Campaign</div>
          </Stack>
        </Button> 
      </Stack>
    </>
  )
}

const EventModal = (props) => {
  const {
    currentEthAddress,
    selectedEvent,
    open,
    handleClose,
    updateSelectedEvent,
  } = props
  const [optedIn, setOptedIn] = React.useState(false)

  React.useEffect(() => {
    console.log('Event Modal entry', { selectedEvent })
    const userOnEvent = selectedEvent?.userToEvents.find((userToEvent) => {
      return userToEvent.user.ethAddress === currentEthAddress
    })
    setOptedIn(userOnEvent?.optedIn || false)
  }, [])

  return (
    <>
      <Modal
        style={{
          position: 'absolute',
          top: '40%',
          left: '25%',
          background: 'white',
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            height: '50vh',
            width: '50vw',
            background: 'white',
            padding: '15px 10px',
            overflow: 'auto',
          }}
        >
          <EventInfo
            selectedEvent={selectedEvent}
            optedIn={optedIn}
            setOptedIn={setOptedIn}
            currentEthAddress={currentEthAddress}
            updateSelectedEvent={updateSelectedEvent}
          />
        </div>
      </Modal>
    </>
  )
}
